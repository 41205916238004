import React from 'react'
import { useEffect } from "react";
import { useStoreState } from 'easy-peasy';

const CheckoutBanner = () => {

    const cart_banner_check = useStoreState((state) => state.cart_banner_check);
    const details_banner_check = useStoreState((state) => state.details_banner_check);
    const payment_banner_check = useStoreState((state) => state.payment_banner_check);
    const order_banner_check = useStoreState((state) => state.order_banner_check);


    useEffect(() => {
        console.log("cart: " + cart_banner_check)
        console.log("details: " + details_banner_check)
        console.log("payment: " + payment_banner_check)
        console.log("order: " + order_banner_check)
    }, [cart_banner_check, details_banner_check, payment_banner_check, order_banner_check])

    const layout = useStoreState((state) => state.layout);

    return (
        <>

            <div className="d-flex flex-row justify-content-between" style={{ height: "50px", width: "100%", alignItems: "center" }}>
            </div>
            <div className="d-flex flex-row justify-content-evenly" style={{ height: "100px", width: "100%", alignItems: "center" }}>
                <div className="d-flex flex-column" style={{ height: "100%", width: "15%" }}>
                    <div className="d-flex-row justify-content-center align-items-center" style={{ height: "30%", width: "100%" }}>
                        {cart_banner_check &&
                            <h4 style={{ color: `${layout.color_main}`, width: '100%', textAlign: 'center' }}>Cart</h4>
                        }
                        {!cart_banner_check &&
                            <h4 style={{ color: `black`, width: '100%', textAlign: 'center'  }}>Cart</h4>
                        }
                    </div>
                    <div className="d-flex-row justify-content-center" style={{ height: "60%", width: "100%" }}>
                        {cart_banner_check &&
                            <i style={{ color: `${layout.color_main}`, width: '100%', textAlign: 'center' }} className="fa-solid fa-bag-shopping fa-3x"></i>
                        }
                        {!cart_banner_check &&
                            <i style={{ color: `black`, width: '100%', textAlign: 'center'  }} className="fa-solid fa-bag-shopping fa-3x"></i>
                        }
                    </div>
                </div>
                <div className="d-flex flex-column align-items-center justify-content-center" style={{ height: "100%", width: "5%" }}>
                    <div className="d-flex-row justify-content-center" style={{ height: "40%", width: "120%" }}>
                        {cart_banner_check &&
                            <div className="d-flex flex-row" style={{ height: "50%", width: "100%", borderBottom: ` dotted ${layout.color_main}` }}></div>
                        }
                        {!cart_banner_check &&
                            <div className="d-flex flex-row" style={{ height: "50%", width: "100%", borderBottom: ` dotted black` }}></div>
                        }
                    </div>
                </div>
                <div className="d-flex flex-column" style={{ height: "100%", width: "20%" }}>
                    <div className="d-flex-row justify-content-center" style={{ height: "30%", width: "100%" }}>
                        {details_banner_check &&
                            <h4 style={{ color: `${layout.color_main}`, width: '100%', textAlign: 'center'  }}>Shipping</h4>
                        }
                        {!details_banner_check &&
                            <h4 style={{ color: `black`, width: '100%', textAlign: 'center'  }}>Shipping</h4>
                        }
                    </div>
                    <div className="d-flex-row justify-content-center" style={{ height: "60%", width: "100%" }}>
                        {details_banner_check &&
                            <i style={{ color: `${layout.color_main}`, width: '100%', textAlign: 'center'  }} className="fa-solid fa-truck-fast fa-3x"></i>
                        }
                        {!details_banner_check &&
                            <i style={{ color: `black`, width: '100%', textAlign: 'center'  }} className="fa-solid fa-truck-fast fa-3x"></i>
                        }
                    </div>
                </div>
                <div className="d-flex flex-column align-items-center justify-content-center" style={{ height: "100%", width: "5%" }}>
                    <div className="d-flex-row justify-content-center" style={{ height: "40%", width: "150%" }}>
                        {details_banner_check &&
                            <div className="d-flex flex-row" style={{ height: "50%", width: "100%", borderBottom: ` dotted ${layout.color_main}` }}></div>
                        }
                        {!details_banner_check &&
                            <div className="d-flex flex-row" style={{ height: "50%", width: "100%", borderBottom: ` dotted black` }}></div>
                        }
                    </div>
                </div>
                <div className="d-flex flex-column" style={{ height: "100%", width: "20%" }}>
                    <div className="d-flex-row justify-content-center" style={{ height: "30%", width: "100%" }}>
                        {payment_banner_check &&
                            <h4 style={{ color: `${layout.color_main}`, width: '100%', textAlign: 'center' }}>Payment</h4>
                        }
                        {!payment_banner_check &&
                            <h4 style={{ color: `black`, width: '100%', textAlign: 'center' }}>Payment</h4>
                        }
                    </div>
                    <div className="d-flex-row justify-content-center " style={{ height: "60%", width: "100%" }}>
                        <div className="d-flex flex-column ps-1" style={{ height: "100%", width: "100%" }}>
                            {payment_banner_check &&
                                <i style={{ color: `${layout.color_main}`, width: '100%', textAlign: 'center' }} className="fa-solid fa-credit-card fa-3x"></i>
                            }
                            {!payment_banner_check &&
                                <i style={{ color: `black`, width: '100%', textAlign: 'center' }} className="fa-solid fa-credit-card fa-3x"></i>
                            }
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-column align-items-center justify-content-center" style={{ height: "100%", width: "5%" }}>
                    <div className="d-flex-row justify-content-center" style={{ height: "40%", width: "200%", position: 'relative' , left: '15px' }}>
                        {payment_banner_check &&
                            <div className="d-flex flex-row" style={{ height: "50%", width: "100%", borderBottom: ` dotted ${layout.color_main}` }}></div>
                        }
                        {!payment_banner_check &&
                            <div className="d-flex flex-row" style={{ height: "50%", width: "100%", borderBottom: ` dotted black` }}></div>
                        }
                    </div>
                </div>
                <div className="d-flex flex-column" style={{ height: "100%", width: "30%" }}>
                    <div className="d-flex-row justify-content-center" style={{ height: "30%", width: "100%" }}>
                        {order_banner_check &&
                            <h4 style={{ color: `${layout.color_main}`, width: '100%', textAlign: 'center' }}>Confirmation</h4>
                        }
                        {!order_banner_check &&
                            <h4 style={{ color: `black`, width: '100%', textAlign: 'center' }}>Confirmation</h4>
                        }
                    </div>
                    <div className="d-flex-row justify-content-center" style={{ height: "60%", width: "100%" }}>
                        <div className="d-flex flex-column ps-1" style={{ height: "100%", width: "100%" }}>
                            {order_banner_check &&
                                <i style={{ color: `${layout.color_main}`, width: '100%', textAlign: 'center' }} className="fa-solid fa-square-check fa-3x"></i>
                            }
                            {!order_banner_check &&
                                <i style={{ color: `black`, width: '100%', textAlign: 'center' }} className="fa-solid fa-square-check fa-3x"></i>
                            }
                        </div>
                    </div>
                </div>
            </div >

        </>
    )
}

export default CheckoutBanner