import React from 'react'
import { useEffect } from 'react';
import { Outlet } from "react-router-dom"
import { useParams } from "react-router-dom";
import { useStoreState, useStoreActions } from 'easy-peasy';
import ListItems from "./ListItems";
import ListTexts from "./ListTexts";
import LoadingPage from '../utility/LoadingPage';

const Category = () => {

    const {ref, subcategory_name } = useParams();
    const page = useStoreState((state) => state.page);
    const category = useStoreState((state) => state.category);
    const set_category_check = useStoreActions((actions) => actions.set_category_check);

    useEffect(() => {
        set_category_check(true)
        return () => {
          set_category_check(false)
        };
      }, [])

    return (
        <>
        {category == 'loading' && <LoadingPage />}
        <>
            {category != 'loading' &&
                <>
                    {ref && <Outlet />}
                    {!ref && 
                        <>
                            {subcategory_name && <Outlet />}
                            {!subcategory_name && 
                                <>
                                    <div className="container-fluid p-0" style={{ height: "100%", width: "100%", color: "black" }}>
                                      
                                        <div className="d-flex flex-row justify-content-center pt-3" style={{ height: "100%" }} >
                                            {page.page_type == "text" &&
                                                <div className="container-fluid p-0" style={{ height: "100%" }}>
                                                    <div className="d-flex flex-row justify-content-center mb-3">
                                                        <h3 style={{ color: "black" }}>{category?.title} </h3>
                                                    </div>
                                                    <ListTexts texts={category.texts} />
                                                </div>
                                            }
                                            {page.page_type == "product" &&
                                                <>
                                                    <div className="container-fluid p-0" style={{ height: "100%", width: "100%" }}>
                                                        <div className="d-flex flex-row justify-content-center">
                                                            <h3 style={{ color: "black" }}>{category?.title}</h3>
                                                        </div>
                                                        <ListItems data={category.products} />
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </>
                            }
                        </>
                    }
                </>
            }
        </>
    </>
    )
}

export default Category