import { useState, useEffect, useRef } from 'react';
import Dropdown from './Dropdown';

import { Link } from 'react-router-dom';

const MenuItems = ({ items, depthLevel }) => {
    const [dropdown, setDropdown] = useState(false);
    let ref = useRef();
    useEffect(() => {
        const handler = (event) => {
            if (
                dropdown &&
                ref.current &&
                !ref.current.contains(event.target)
            ) {
                setDropdown(false);
            }
        };
        document.addEventListener('mousedown', handler);
        document.addEventListener('touchstart', handler);
        return () => {
            // Cleanup the event listener
            document.removeEventListener('mousedown', handler);
            document.removeEventListener('touchstart', handler);
        };
    }, [dropdown]);

    const onMouseEnter = () => {
        window.innerWidth > 960 && setDropdown(true);
    };

    const onMouseLeave = () => {
        window.innerWidth > 960 && setDropdown(false);
    };

    const closeDropdown = () => {
        dropdown && setDropdown(false);
    };

    return (
        <li
            className="menu-items"
            ref={ref}
            style={depthLevel > 0 ? {width: '160px'} : items?.title?.length > 6 ? { width: '120px'} : {width: '80px'}}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={closeDropdown}
        >
            {items?.name && items?.categories?.length ? (
                <>
                    <button
                        type="button"
                        aria-haspopup="menu"
                        aria-expanded={dropdown ? 'true' : 'false'}
                        onClick={() => setDropdown((prev) => !prev)}
                    >
                        {window.innerWidth < 960 && depthLevel === 0 ? (
                            items.title
                        ) : (
                            <Link to={`/${items?.name}`}>{items?.title}</Link>
                        )}

                        {depthLevel > 0 &&
                            window.innerWidth < 960 ? null : depthLevel > 0 &&
                                window.innerWidth > 960 ? (
                            <span>&raquo;</span>
                        ) : (
                            <span className="arrow" />
                        )}
                    </button>
                    <Dropdown
                        depthLevel={depthLevel}
                        submenus={items?.categories}
                        dropdown={dropdown}
                    />
                </>
            ) : !items?.name && items?.categories?.length ? (
                <>
                    <button
                        type="button"
                        aria-haspopup="menu"
                        aria-expanded={dropdown ? 'true' : 'false'}
                        onClick={() => setDropdown((prev) => !prev)}
                    >
                        {items.title}{' '}
                        {depthLevel > 0 ? (
                            <span>&raquo;</span>
                        ) : (
                            <span className="arrow" />
                        )}
                    </button>
                    <Dropdown
                        depthLevel={depthLevel}
                        submenus={items?.categories}
                        dropdown={dropdown}
                    />
                </>
            ) : items?.name && items?.categories && items.categories?.length == 0 && items.page_type == "home" ? (
                <>
                    <button
                        type="button"
                        aria-haspopup="menu"
                    >
                        {window.innerWidth < 960 && depthLevel === 0 ? (
                            items.title
                        ) : (
                            <Link to={`/`}>{items?.title}</Link>
                        )}
                    </button>
                </>
            ) : !items?.name && items?.categories && items.categories?.length == 0 && items.page_type == "home" ? (
                <>
                    <button
                        type="button"
                        aria-haspopup="menu"
                    >
                        {items?.title}{' '}
                    </button>
                </>
            ) : items?.name && items?.categories && items.categories?.length == 0 && items.page_type != "home" ? (
                <>
                    <button
                        type="button"
                        aria-haspopup="menu"
                    >
                        {window.innerWidth < 960 && depthLevel === 0 ? (
                            items.title
                        ) : (
                            <Link to={`/${items?.name}`}>{items?.title}</Link>
                        )}
                    </button>
                </>
            ) : !items?.name && items?.categories && items.categories?.length == 0 && items.page_type != "home" ? (
                <>
                    <button
                        type="button"
                        aria-haspopup="menu"
                    >
                        {items?.title}{' '}
                    </button>
                </>
            ) : items?.name && items?.subcategories?.length ? (
                <>
                    <button
                        type="button"
                        aria-haspopup="menu"
                        aria-expanded={dropdown ? 'true' : 'false'}
                        onClick={() => setDropdown((prev) => !prev)}
                    >
                        {window.innerWidth < 960 && depthLevel === 0 ? (
                            items.title
                        ) : (
                            <Link to={`/${items?.page?.name}/${items?.name}`}>{items?.title}</Link>
                        )}

                        {depthLevel > 0 &&
                            window.innerWidth < 960 ? null : depthLevel > 0 &&
                                window.innerWidth > 960 ? (
                            <span>&raquo;</span>
                        ) : (
                            <span className="arrow" />
                        )}
                    </button>
                    <Dropdown
                        depthLevel={depthLevel}
                        submenus={items.subcategories}
                        dropdown={dropdown}
                    />
                </>
            ) : !items?.name && items?.subcategories?.length ? (
                <>
                    <button
                        type="button"
                        aria-haspopup="menu"
                        aria-expanded={dropdown ? 'true' : 'false'}
                        onClick={() => setDropdown((prev) => !prev)}
                    >
                        {items.title}{' '}
                        {depthLevel > 0 ? (
                            <span>&raquo;</span>
                        ) : (
                            <span className="arrow" />
                        )}
                    </button>
                    <Dropdown
                        depthLevel={depthLevel}
                        submenus={items?.subcategories}
                        dropdown={dropdown}
                    />
                </>
            ) : items?.name && items?.subcategories && items.subcategories?.length == 0 ? (
                <>
                    <button
                        type="button"
                        aria-haspopup="menu"
                    >
                        {window.innerWidth < 960 && depthLevel === 0 ? (
                            items.title
                        ) : (
                            <Link to={`/${items?.page?.name}/${items?.name}`}>{items?.title}</Link>
                        )}
                    </button>
                </>
            ) : !items?.name && items?.subcategories && items.subcategories?.length == 0 ? (
                <>
                    <button
                        type="button"
                        aria-haspopup="menu"
                    >
                        {items?.title}{' '}
                    </button>
                </>
            ) : items?.name && items?.subcategories2?.length ? (
                <>
                    <button
                        type="button"
                        aria-haspopup="menu"
                        aria-expanded={dropdown ? 'true' : 'false'}
                        onClick={() => setDropdown((prev) => !prev)}
                    >
                        {window.innerWidth < 960 && depthLevel === 0 ? (
                            items.title
                        ) : (
                            <Link to={`/${items?.page?.name}/${items?.category?.name}/${items?.name}`}>{items?.title}</Link>
                        )}

                        {depthLevel > 0 &&
                            window.innerWidth < 960 ? null : depthLevel > 0 &&
                                window.innerWidth > 960 ? (
                            <span>&raquo;</span>
                        ) : (
                            <span className="arrow" />
                        )}
                    </button>
                    <Dropdown
                        depthLevel={depthLevel}
                        submenus={items?.subcategories2}
                        dropdown={dropdown}
                    />
                </>
            ) : !items?.name && items?.subcategories2?.length ? (
                <>
                    <button
                        type="button"
                        aria-haspopup="menu"
                        aria-expanded={dropdown ? 'true' : 'false'}
                        onClick={() => setDropdown((prev) => !prev)}
                    >
                        {items.title}{' '}
                        {depthLevel > 0 ? (
                            <span>&raquo;</span>
                        ) : (
                            <span className="arrow" />
                        )}
                    </button>
                    <Dropdown
                        depthLevel={depthLevel}
                        submenus={items?.subcategories2}
                        dropdown={dropdown}
                    />
                </>
            ) : (
                <Link to={`/${items?.page?.name}/${items?.category?.name}/${items?.subcategory?.name}/${items?.name}`}>{items?.title}</Link>
            )}
        </li>
    );
};

export default MenuItems;