import { useStoreState, useStoreActions } from 'easy-peasy';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useEffect } from "react";
import Card from 'react-bootstrap/Card';
import { Dropdown, DropdownButton, ListGroup, SplitButton } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import DropdownMenu from 'react-bootstrap/esm/DropdownMenu';
import MenuItemsSmall from './MenuItemsSmall';

const NavMenu = () => {

    const navbar = useStoreState((state) => state.navbar);
    const layout = useStoreState((state) => state.layout);
    const layout_height = useStoreState((state) => state.layout_height);
    const dropdown1 = useStoreState((state) => state.dropdown1);
    const set_dropdown1 = useStoreActions((actions) => actions.set_dropdown1);
    const dropdown2 = useStoreState((state) => state.dropdown2);
    const set_dropdown2 = useStoreActions((actions) => actions.set_dropdown2);

    const handleDropDown1 = () => {
        if (dropdown1 == true) {
            set_dropdown1(false)
        } else {
            set_dropdown1(true)
        }
    }
    const handleDropDown2 = () => {
        if (dropdown2 == true) {
            set_dropdown2(false)
        } else {
            set_dropdown2(true)
        }
    }
    return (
        <>
            <i className="fa-solid fa-bars" onClick={() => handleDropDown1()} ></i>
            {dropdown1 == true &&
                <ListGroup className="list-group-flush" style={{ position: 'absolute', height: `${layout_height+300}px`, left: '0px', top: '0px', zIndex: '4', width: '250px', backgroundColor: `${layout?.color_main}`, border: 'none' }}>
                    {navbar.map((menu, index) => {
                        const depthLevel = 0;
                        return (
                            <MenuItemsSmall
                                items={menu}
                                key={index}
                                depthLevel={depthLevel}
                            />
                        );
                    })}
                </ListGroup>
            }
        </>
    )
}

export default NavMenu
