import { useStoreState, useStoreActions } from 'easy-peasy';
import FeedItem from './FeedItem';
import FeedItemSmall from './FeedItemSmall';

const Feed = ({ data }) => {

    const width = useStoreState((state) => state.width);

    return (
        <>
            <div className="d-flex flex-wrap justify-content-around" style={{ width: "100%" }}>
                {data?.map(data => (
                    <>
                        {width >= 992 &&
                            <FeedItem key={data?.id} data={data} />
                        }
                        {width < 992 &&
                            <FeedItemSmall key={data?.id} data={data} />
                        }
                    </>
                ))}
            </div>
        </>
    )
}

export default Feed
