import { useStoreState, useStoreActions } from 'easy-peasy';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useEffect } from "react";
import Card from 'react-bootstrap/Card';
import { Dropdown, DropdownButton, ListGroup, SplitButton } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import DropdownMenu from 'react-bootstrap/esm/DropdownMenu';
import MenuItems from './MenuItems';

const NavMenu = () => {

    const navbar = useStoreState((state) => state.navbar);
    const layout = useStoreState((state) => state.layout);

    return (
        <nav style={{ backgroundColor: `${layout?.color_main}`, height: "50px", position: "sticky", top: "0px", zIndex: "2" }}>
            <div className="row" style={{ width: '100%' }}>
                <div className="col-6 align-items-center">
                    <ul className="menus" style={{ color: 'white' }}>
                        {navbar.map((menu, index) => {
                            const depthLevel = 0;
                            return (
                                <MenuItems
                                    items={menu}
                                    key={index}
                                    depthLevel={depthLevel}
                                />
                            );
                        })}

                    </ul>
                </div>
                <div className="col-6" >
                    <div className="d-flex flex-row-reverse align-items-center" style={{height:'50px'}}>
                        <a className="fa-solid fa-cart-shopping" href="/checkout/cart" style={{ color: 'white', textDecoration: 'none' }}></a>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default NavMenu
