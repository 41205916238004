import React from 'react'
import { useNavigate } from "react-router-dom";
import { useStoreState } from 'easy-peasy';
import useAuth from "../../hooks/useAuth";
import { Dropdown } from 'react-bootstrap';
import { DropdownButton } from 'react-bootstrap';


const AuthButtonsSmall = () => {
    const { auth, setAuth } = useAuth();
    const navigate = useNavigate();
    const layout = useStoreState((state) => state.layout);

    const signOut = () => {
        setAuth("");
        localStorage.setItem('refresh_token', "")
        navigate(`/`);
    }
    return (
        <div className="d-flex flex-row align-items-center justify-content-center" style={{ width: '70px', height: '50px' }}>
            {!auth?.id &&
                <>
                    <div className="dropdown show p-0 " style={{ minWidth: '30px', width: '30px', height: '20px', backgroundColor: '#f2f2f2', border: '0px hidden' }}>
                        <a className="fa-solid fa-cart-shopping" href="/checkout/cart" style={{ width: '30px', color: `${layout?.color_main}`, textDecoration: 'none' }}></a>
                    </div>
                    <div className="dropdown show p-0" style={{ minWidth: '30px', width: '30px', height: '20px', backgroundColor: '#f2f2f2', border: '0px hidden' }}>
                        <button className="small-dropdown dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" style={{ width: '30px', backgroundColor: '#f2f2f2', border: 'hidden' }}>
                            <i className="fa-solid fa-user" style={{ color: `${layout?.color_main}`, textDecoration: 'none' }}></i>
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li><a className="dropdown-item" href="/login">Login</a></li>
                            <li><a className="dropdown-item" href="/register">Register</a></li>
                        </ul>
                    </div>
                </>
            }
            {auth?.id &&
                <>
                    <div className="dropdown show p-0 " style={{ minWidth: '30px', width: '30px', height: '20px', backgroundColor: '#f2f2f2', border: '0px hidden' }}>
                        <a className="fa-solid fa-cart-shopping" href="/checkout/cart" style={{ width: '30px', color: `${layout?.color_main}`, textDecoration: 'none' }}></a>
                    </div>
                    <div className="dropdown show p-0 " style={{ minWidth: '30px', width: '30px', height: '20px', backgroundColor: '#f2f2f2', border: '0px hidden' }}>
                        <button className="small-dropdown dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" style={{ width: '50px', backgroundColor: '#f2f2f2', border: 'hidden' }}>
                            <i className="fa-solid fa-user" style={{ color: `${layout?.color_main}`, textDecoration: 'none' }}></i>
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li><a className="dropdown-item" href="/profile">Profile</a></li>
                            <li><a className="dropdown-item" onClick={signOut}>Sign Out</a></li>
                        </ul>
                    </div>
                </>
            }

        </div>
    )
}

export default AuthButtonsSmall