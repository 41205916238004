import { useStoreState, useStoreActions } from 'easy-peasy';
import { useState, useEffect } from 'react';
import Feed from './Feed';
import Pagination from 'react-bootstrap/Pagination';
import { useParams } from 'react-router-dom';

const ListItems = ({ data }) => {

    const width = useStoreState((state) => state.width);

    const [pageN, setPage] = useState(1);

    const [itemsPerPage, setItemsPerPage] = useState(8);

    const items = useStoreState((state) => state.items);
    const dirs = useStoreState((state) => state.dirs);
    const set_items = useStoreActions((actions) => actions.set_items);
    const set_dirs = useStoreActions((actions) => actions.set_dirs);
    const [left, setLeft] = useState("75%");

    useEffect(() => {
        if (data?.length < 20) {
            setItemsPerPage(data.length)
        }
        let i = 0;
        let itemList = []
        if (data?.length) {
            while (i < itemsPerPage) {
                itemList.push(data[i])
                i = i + 1
            }
        }
        set_items(itemList);
        let total = data?.length
        if (total <= itemsPerPage) {
            set_dirs(1);
        }
        else if (total > itemsPerPage && total <= (itemsPerPage * 2)) {
            set_dirs(2)
        }
        else {
            if (total % itemsPerPage == 0) {
                set_dirs(((total / itemsPerPage)))
            }
            else {
                set_dirs((Math.ceil(total / itemsPerPage)))
            }

        }


    }, [data, set_items, itemsPerPage])

    useEffect(() => {
        if (width >= 294 && width < 362) {
            setLeft("5%")
        }
        else if (width >= 362 && width < 992) {
            setLeft("30%")
        }
        else if (width >= 992 && width < 1400) {
            setLeft("50%")
        }
        else if (width >= 1400) {
            setLeft("65%")
        }
    }, [width])
    useEffect(() => {
        if (width >= 1846) {
            if (data?.length < 28) {
                setItemsPerPage(data?.length)
            }
            else {
                setItemsPerPage(28)
            }
        }
        if (width >= 1764 && width < 1865) {
            if (data?.length < 28) {
                setItemsPerPage(data?.length)
            }
            else {
                setItemsPerPage(28)
            }
        }
        if (width >= 1515 && width < 1765) {
            if (data?.length < 24) {
                setItemsPerPage(data?.length)
            }
            else {
                setItemsPerPage(24)
            }
        }
        if (width >= 1265 && width < 1515) {
            if (data?.length < 20) {
                setItemsPerPage(data?.length)
            }
            else {
                setItemsPerPage(20)
            }
        }
        if (width >= 1015 && width < 1265) {
            if (data?.length < 16) {
                setItemsPerPage(data?.length)
            }
            else {
                setItemsPerPage(16)
            }
        }
        if (width >= 992 && width < 1015) {
            if (data?.length < 12) {
                setItemsPerPage(data?.length)
            }
            else {
                setItemsPerPage(12)
            }
        }
        if (width >= 928 && width < 992) {
            if (data?.length < 20) {
                setItemsPerPage(data?.length)
            }
            else {
                setItemsPerPage(20)
            }
        }
        if (width >= 745 && width < 928) {
            if (data?.length < 16) {
                setItemsPerPage(data?.length)
            }
            else {
                setItemsPerPage(16)
            }
        }
        if (width >= 535 && width < 745) {
            if (data?.length < 12) {
                setItemsPerPage(data?.length)
            }
            else {
                setItemsPerPage(12)
            }
        }
        if (width >= 453 && width < 535) {
            if (data?.length < 12) {
                setItemsPerPage(data?.length)
            }
            else {
                setItemsPerPage(12)
            }
        }
        if (width >= 307 && width < 453) {
            if (data?.length < 8) {
                setItemsPerPage(data?.length)
            }
            else {
                setItemsPerPage(8)
            }
        }
        if (width < 307) {
            if (data?.length < 4) {
                setItemsPerPage(data?.length)
            }
            else {
                setItemsPerPage(4)
            }
        }

    }, [width])


    let pages = [<Pagination.Item key="back" onClick={(e) => handleBack(e)}>
        <i className="fa-solid fa-arrow-left"></i>
    </Pagination.Item>,]
    if (width < 765) {
        if (dirs > 5) {
            if (pageN > 4) {
                pages.push(
                    <Pagination.Item key="1" onClick={(e) => handleClick(e)}>
                        {1}
                    </Pagination.Item>,
                );
                pages.push(
                    <Pagination.Item key="2" onClick={(e) => handleClick(e)}>
                        {2}
                    </Pagination.Item>,
                );
                pages.push(
                    <Pagination.Item key="3" onClick={(e) => handleClick(e)}>
                        {3}
                    </Pagination.Item>,
                );
                for (let number = pageN; number <= pageN + 1; number++) {
                    pages.push(
                        <Pagination.Item key={number} active={number === pageN} onClick={(e) => handleClick(e)}>
                            {number}
                        </Pagination.Item>,
                    );
                }
            }
            else {
                for (let number = 1; number <= 5; number++) {
                    pages.push(
                        <Pagination.Item key={number} active={number === pageN} onClick={(e) => handleClick(e)}>
                            {number}
                        </Pagination.Item>,
                    );
                }
            }

        }
        else if (dirs <= 5) {
            for (let number = 1; number <= dirs; number++) {
                pages.push(
                    <Pagination.Item key={number} active={number === pageN} onClick={(e) => handleClick(e)}>
                        {number}
                    </Pagination.Item>,
                );
            }
        }
        if (pageN == dirs) {
            pages.push(
                <Pagination.Item key="forward">
                    <i className="fa-solid fa-arrow-right"></i>
                </Pagination.Item>,
            );
        }
        else {
            pages.push(
                <Pagination.Item key="forward" onClick={(e) => handleForward(e)}>
                    <i className="fa-solid fa-arrow-right"></i>
                </Pagination.Item>,
            );
        }
    }
    else {
        if (dirs > 5) {
            if (pageN + 3 >= dirs) {
                pages.push(
                    <Pagination.Item key="1" onClick={(e) => handleClick(e)}>
                        {1}
                    </Pagination.Item>,
                );
                pages.push(
                    <Pagination.Item key="dots">
                        <i>...</i>
                    </Pagination.Item>,
                );
                for (let number = pageN - 3; number <= dirs; number++) {
                    pages.push(
                        <Pagination.Item key={number} active={number === pageN} onClick={(e) => handleClick(e)}>
                            {number}
                        </Pagination.Item>,
                    );
                }
            }
            else {
                for (let number = pageN; number <= pageN + 3 && pageN <= dirs; number++) {
                    pages.push(
                        <Pagination.Item key={number} active={number === pageN} onClick={(e) => handleClick(e)}>
                            {number}
                        </Pagination.Item>,
                    );
                }
                pages.push(
                    <Pagination.Item key="dots">
                        <i>...</i>
                    </Pagination.Item>,
                );
                pages.push(
                    <Pagination.Item key="last" onClick={(e) => handleClick(e)}>
                        {dirs}
                    </Pagination.Item>,
                );
            }

        }
        else if (dirs <= 5) {
            for (let number = 1; number <= dirs; number++) {
                pages.push(
                    <Pagination.Item key={number} active={number === pageN} onClick={(e) => handleClick(e)}>
                        {number}
                    </Pagination.Item>,
                );
            }
        }
        if (dirs > 5 && pageN + 3 >= dirs) {
            pages.push(
                <Pagination.Item key="forward">
                    <i className="fa-solid fa-arrow-right"></i>
                </Pagination.Item>,
            );
        }
        else {
            pages.push(
                <Pagination.Item key="forward" onClick={(e) => handleForward(e)}>
                    <i className="fa-solid fa-arrow-right"></i>
                </Pagination.Item>,
            );
        }
    }


    const handleBack = async (e) => {

        e.preventDefault();
        setPage(pageN - 1)
        let offset = ((pageN - 1) - 1) * itemsPerPage
        let i = 0;
        let itemList = [];
        if (data?.length) {
            while (i < itemsPerPage) {
                itemList.push(data[i + offset])
                i = i + 1
            }
        }
        set_items(itemList)
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // for smoothly scrolling
        });
    }
    const handleForward = async (e) => {

        e.preventDefault();
        setPage(pageN + 1)
        let offset = ((pageN + 1) - 1) * itemsPerPage
        let i = 0;
        let itemList = [];
        if (data?.length) {
            while (i < itemsPerPage) {
                itemList.push(data[i + offset])
                i = i + 1
            }
        }
        set_items(itemList)
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // for smoothly scrolling
        });
    }
    const handleClick = async (e) => {

        e.preventDefault();
        setPage(parseInt(e.nativeEvent.target.firstChild.data))
        let offset = (parseInt(e.nativeEvent.target.firstChild.data) - 1) * itemsPerPage
        let i = 0;
        let itemList = [];
        if (data?.length) {
            if (((parseInt(e.nativeEvent.target.firstChild.data)) * itemsPerPage) >= data?.length) {
                let diffPerPage = data?.length - ((parseInt(e.nativeEvent.target.firstChild.data) - 1) * itemsPerPage)
                while (i < diffPerPage) {
                    itemList.push(data[i + offset])
                    i = i + 1
                }
            }
            else {
                while (i < itemsPerPage) {
                    itemList.push(data[i + offset])
                    i = i + 1
                }
            }
        }
        set_items(itemList)
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // for smoothly scrolling
        });
    }

    return (
        <div className="d-flex flex-row justify-content-center" style={{ height: "100%", width: '100%' }}>

            {width >= 563 &&
                <div style={{ height: "85%", width: '80%' }}>
                    <main className="Home" style={{ height: "100%" }}>
                        <div style={{ height: "90%" }}>
                            {(items?.length ? <Feed data={items} /> : <p className="statusMsg">No products to display.</p>)}
                        </div>
                    </main>
                    <Pagination style={{ position: "relative", top: '50px', left: `${left}`, width: '20%' }} >{pages}</Pagination>
                </div>
            }
            {width < 563 &&
                <div style={{ height: "85%", width: '100%' }}>
                    <main className="Home" style={{ height: "100%" }}>
                        <div style={{ height: "90%" }}>
                            {(items?.length ? <Feed data={items} /> : <p className="statusMsg">No products to display.</p>)}
                        </div>
                    </main>
                    <Pagination style={{ position: "relative", top: '50px', left: `${left}`, width: '20%' }} >{pages}</Pagination>
                </div>
            }
        </div>



    )
}

export default ListItems
