import { useEffect } from 'react';
import { Outlet } from "react-router-dom"
import { useParams } from "react-router-dom";
import { useStoreActions, useStoreState } from 'easy-peasy';
import useAuth from "../../hooks/useAuth";


const PageSizing = () => {

        const { page_name } = useParams();
        const { category_name } = useParams();
        const { ref } = useParams();
        const page = useStoreState((state) => state.page);
        const width = useStoreState((state) => state.width);
        const category = useStoreState((state) => state.category);
        const subcategory = useStoreState((state) => state.subcategory);
        const subcategory2 = useStoreState((state) => state.subcategory2);
        const cart_items = useStoreState((state) => state.cart_items);
        const set_height = useStoreActions((actions) => actions.set_height);
        const set_layout_height = useStoreActions((actions) => actions.set_layout_height);
        const page_check = useStoreState((state) => state.page_check);
        const category_check = useStoreState((state) => state.category_check);
        const subcategory_check = useStoreState((state) => state.subcategory_check);
        const subcategory2_check = useStoreState((state) => state.subcategory2_check);
        const item_check = useStoreState((state) => state.item_check);

        useEffect(() => {
                if (item_check) {
                        set_height(1400);
                        set_layout_height(1800);
                }
                else if (subcategory2_check) {
                        if (page?.page_type == "text") {
                                let totalheight = 0
                                for (let index = 0; index < subcategory2?.texts?.length; index++) {
                                        if (width < 992) {
                                                totalheight = totalheight + subcategory2?.texts[index].height_small;
                                        }
                                        else {
                                                totalheight = totalheight + subcategory2?.texts[index].height;
                                        }
                                }
                                set_height(totalheight + 300);
                                set_layout_height(totalheight + 600);
                        }
                        else {
                                if (width < 992) {
                                        set_height(1700);
                                        set_layout_height(2100);
                                }
                                else {
                                        set_height(1900);
                                        set_layout_height(2400);
                                }
                        }
                }
                else if (subcategory_check) {
                        if (page?.page_type == "text") {
                                let totalheight = 0
                                for (let index = 0; index < subcategory?.texts?.length; index++) {
                                        if (width < 992) {
                                                totalheight = totalheight + subcategory?.texts[index].height_small;
                                        }
                                        else {
                                                totalheight = totalheight + subcategory?.texts[index].height;
                                        }
                                }
                                set_height(totalheight + 300);
                                set_layout_height(totalheight + 600);
                        }
                        else {
                                if (width < 992) {
                                        set_height(1700);
                                        set_layout_height(2100);
                                }
                                else {
                                        set_height(1900);
                                        set_layout_height(2400);
                                }
                        }
                }
                else if (category_check) {
                        if (page?.page_type == "text") {
                                let totalheight = 0
                                for (let index = 0; index < category?.texts?.length; index++) {
                                        if (width < 992) {
                                                totalheight = totalheight + category?.texts[index].height_small;
                                        }
                                        else {
                                                totalheight = totalheight + category?.texts[index].height;
                                        }
                                }
                                set_height(totalheight + 300);
                                set_layout_height(totalheight + 600);
                        }
                        else {
                                if (width < 992) {
                                        set_height(1700);
                                        set_layout_height(2100);
                                }
                                else {
                                        set_height(1900);
                                        set_layout_height(2400);
                                }
                        }
                }
                else if (page_check && page?.page_type != "home" && page?.page_type != "contact") {
                        if (page?.page_type == "contact") {
                                set_height(2200);
                                set_layout_height(2700);
                        }
                        else if (page?.page_type == "text") {
                                let totalheight = 0
                                for (let index = 0; index < page?.texts?.length; index++) {
                                        if (width < 992) {
                                                totalheight = totalheight + page?.texts[index].height_small;
                                        }
                                        else {
                                                totalheight = totalheight + page?.texts[index].height;
                                        }
                                }
                                set_height(totalheight + 300);
                                set_layout_height(totalheight + 600);
                        }
                        else {
                                if (width < 992) {
                                        set_height(1700);
                                        set_layout_height(2200);
                                }
                                else {
                                        set_height(1900);
                                        set_layout_height(2400);
                                }
                        }
                }
                else if (page_check && page?.page_type == "home") {
                        if (width < 811) {
                                set_height(600);
                                set_layout_height(1100);
                        }
                        else {
                                set_height(900);
                                set_layout_height(1400);
                        }
                }
                else if (page_check && page?.page_type == "contact") {
                        if (width < 811) {
                                set_height(600);
                                set_layout_height(1100);
                        }
                        else {
                                set_height(1000);
                                set_layout_height(1500);
                        }
                }
                else if (window.location.pathname == "/checkout/cart") {
                        let newHeight = 1000
                        if (cart_items?.length > 3) {
                                newHeight = 400 + (cart_items?.length * 200)
                        }
                        set_height(newHeight);
                        set_layout_height(newHeight + 500);
                }
                else if (window.location.pathname == "/checkout/details") {
                        set_height(1300);
                        set_layout_height(1800);
                }
                else if (window.location.pathname == "/checkout/payment") {
                        set_height(1000);
                        set_layout_height(1500);
                }
                else {
                        set_height(1000);
                        set_layout_height(1500);
                }

        }, [page_check, category_check, subcategory_check, subcategory2_check, item_check, page, category, subcategory, subcategory2, ref, width])

        return (
                <>
                        <Outlet />
                </>
        )
}

export default PageSizing
