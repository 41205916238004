import React from 'react'
import { useStoreState } from 'easy-peasy';

const HeaderLogoSmall = () => {
    const layout = useStoreState((state) => state.layout);
    const width = useStoreState((state) => state.width);
    return (
        <>
            {width >= 771 &&
                <div className="d-flex flex-column">
                    <img src={`https://${process.env.REACT_APP_IMAGE_DISTRO}/img/${layout?.logo}`} style={{ width: '270px', height: "90px" }} className="rounded-pill">
                    </img>
                </div>
            }
            {width >= 663 && width < 771 &&
                <div className="d-flex flex-column">
                    <img src={`https://${process.env.REACT_APP_IMAGE_DISTRO}/img/${layout?.logo}`} style={{ width: '240px', height: "80px" }} className="rounded-pill">
                    </img>
                </div>
            }
            {width >= 521 && width < 662 &&
                <div className="d-flex flex-column">
                    <img src={`https://${process.env.REACT_APP_IMAGE_DISTRO}/img/${layout?.logo}`} style={{ width: '210px', height: "70px" }} className="rounded-pill">
                    </img>
                </div>
            }
            {width >= 421 && width < 520 &&
                <div className="d-flex flex-column">
                    <img src={`https://${process.env.REACT_APP_IMAGE_DISTRO}/img/${layout?.logo}`} style={{ width: '180px', height: "60px" }} className="rounded-pill">
                    </img>
                </div>
            }
            {width >= 321 && width < 420 &&
                <div className="d-flex flex-column">
                    <img src={`https://${process.env.REACT_APP_IMAGE_DISTRO}/img/${layout?.logo}`} style={{ width: '150px', height: "50px" }} className="rounded-pill">
                    </img>
                </div>
            }
            {width < 320 &&
                <div className="d-flex flex-column">
                    <img src={`https://${process.env.REACT_APP_IMAGE_DISTRO}/img/${layout?.logo}`} style={{ width: '120px', height: "40px" }} className="rounded-pill">
                    </img>
                </div>
            }
        </>
    )
}

export default HeaderLogoSmall