import { useStoreState, useStoreActions } from 'easy-peasy';
import DisplayText from './DisplayText';
import DisplayTextSmall from './DisplayTextSmall';

const Display = ({ data }) => {

    const texts = useStoreState((state) => state.texts);
    const width = useStoreState((state) => state.width);

    return (
        <>
            {width >= 992 &&
                <div className="d-flex flex-wrap" style={{ width: "1200px" }}>
                    {texts.map(data => (
                            <DisplayText key={data.id} data={data} />
                    ))}
                </div>
            }
            {width < 992 &&
                <div className="d-flex flex-wrap" style={{ width: "100%" }}>
                    {texts.map(data => (
                            <DisplayTextSmall key={data.id} data={data} />
                    ))}
                </div>
            }
        </>
    )
}

export default Display
