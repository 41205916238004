import React from 'react'
import { useParams } from "react-router-dom";
import { useStoreState } from 'easy-peasy';

const Title = () => {

    const { page_name, category_name, subcategory_name, subcategory2_name, ref } = useParams();
    const page = useStoreState((state) => state.page);
    const category = useStoreState((state) => state.category);
    const subcategory = useStoreState((state) => state.subcategory);
    const subcategory2 = useStoreState((state) => state.subcategory2);
    const item = useStoreState((state) => state.item);

    const ref1 = "/" + page_name;
    const ref2 = "/" + page_name + "/" + category_name;
    const ref3 = "/" + page_name + "/" + category_name + "/item/" + item?.id;
    const ref4 = "/" + page_name + "/" + category_name + "/" + subcategory_name;
    const ref5 = "/" + page_name + "/" + category_name + "/" + subcategory_name + "/item/" + item?.id;
    const ref6 = "/" + page_name + "/" + category_name + "/" + subcategory_name + "/" + subcategory2_name;
    const ref7 = "/" + page_name + "/" + category_name + "/" + subcategory_name + "/" + subcategory2_name + "/item/" + item?.id;

    return (

        <div className="container-fluid p-0" style={{ height: "20px", width: "85%", color: "black" }}>
            <div className='d-flex flex-row justify-content-left' style={{ height: "100%", fontSize: "12px", color: "black" }}>
                <nav aria-label="breadcrumb" >
                    <ol className="breadcrumb">
                        <a href={ref1} className="breadcrumb-item" style={{ textDecoration: "none", color: 'black' }} aria-current="page"> {page?.title}</a>
                        {category_name && <a href={ref2} className="breadcrumb-item" style={{ textDecoration: "none", color: 'black' }} aria-current="page"> {category?.title}</a>}
                        {!subcategory_name && ref && <a href={ref3} className="breadcrumb-item" style={{ textDecoration: "none", color: 'black' }} aria-current="page"> {item?.title}</a>}
                        {subcategory_name && <a href={ref4} className="breadcrumb-item" style={{ textDecoration: "none", color: 'black' }} aria-current="page"> {subcategory?.title}</a>}
                        {subcategory_name && !subcategory2_name && ref && <a href={ref5} className="breadcrumb-item" style={{ textDecoration: "none", color: 'black' }} aria-current="page"> {item?.title}</a>}
                        {subcategory2_name && <a href={ref6} className="breadcrumb-item" style={{ textDecoration: "none", color: 'black' }} aria-current="page"> {subcategory2?.title}</a>}
                        {subcategory_name && subcategory2_name && ref && <a href={ref7} className="breadcrumb-item" style={{ textDecoration: "none", color: 'black' }} aria-current="page"> {item?.title}</a>}
                    </ol>
                </nav>
            </div>
        </div>
    )
}

export default Title