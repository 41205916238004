import React from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { AddressElement } from '@stripe/react-stripe-js';
import Form from 'react-bootstrap/Form';
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import api from '../../api/axios';
import useLocalStorage from "../../hooks/useLocalStorage";
import Summary from "./Summary";
import SummarySmall from "./SummarySmall";

const stripePromise = loadStripe('pk_test_51MMvMaKzUCwJZcNBaRe1YRRgadCztXmrltaGf0Dpalr2jJyYQixZhQNxuFQAmwmnTPkzX9rg3PcfMzEASFpzazjQ00EK7wCSe2');

const ShippingInfo = () => {

    const navigate = useNavigate();
    const { auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const [payment_intent] = useLocalStorage('payment_intent', null);
    const set_details_check = useStoreActions((actions) => actions.set_details_check);
    const set_shipping_info = useStoreActions((actions) => actions.set_shipping_info);
    const set_shipping_rate_list = useStoreActions((actions) => actions.set_shipping_rate_list);
    const set_shipping_rate = useStoreActions((actions) => actions.set_shipping_rate);
    const set_cart_banner_check = useStoreActions((actions) => actions.set_cart_banner_check);
    const set_details_banner_check = useStoreActions((actions) => actions.set_details_banner_check);
    const cart_items = useStoreState((state) => state.cart_items);
    const width = useStoreState((state) => state.width);
    const shipping_info = useStoreState((state) => state.shipping_info);
    const shipping_rate = useStoreState((state) => state.shipping_rate);
    const shipping_rate_list = useStoreState((state) => state.shipping_rate_list);

    const options = {
        appearance: {/*****/ },
    }

    useEffect(() => {
        set_details_check(true)
        set_details_banner_check(true)
        set_cart_banner_check(true)
        return () => {
            set_details_check(false)
            set_details_banner_check(false)
            set_cart_banner_check(false)
        };
    }, [])

    useEffect(() => {
        const payload = {
            uuid: `${process.env.REACT_APP_UUID}`
        }
        const getPaymentInfo = async (payload) => {
            try {
                const response = await api.post(`/tier1/payment/retrieve_intent_without_details/${payment_intent}`, payload);
                console.log(response.data?.payment_info?.body)
            } catch (err) {
                console.log(`Error: ${err.message}`);
            }
        }
        console.log(payment_intent)
        console.log(typeof payment_intent)
        if (payment_intent != null) {
            getPaymentInfo(payload)
        }
    }, [payment_intent])

    useEffect(() => {
        const payload = {
            uuid: `${process.env.REACT_APP_UUID}`,
            shipping_info: shipping_info,
        }
        const getShippingRates = async (payload) => {
            try {
                const response = await api.post('/tier1/payment/shipping_rates', payload);
                set_shipping_rate_list(response.data?.rates?.rate_response?.rates)
            } catch (err) {
                console.log(`Error: ${err.message}`);
            }
        }
        if (shipping_info) {
            getShippingRates(payload)
        }
    }, [shipping_info, set_shipping_info])


    const handleProceed = async () => {
        let new_shipping_rate = {}
        for (let index = 0; index < shipping_rate_list.length; index++) {
            if (shipping_rate == shipping_rate_list[index].rate_id) {
                new_shipping_rate = shipping_rate_list[index];
            }
        }
        console.log(new_shipping_rate)
        const payload = {
            uuid: `${process.env.REACT_APP_UUID}`,
            shipping_rate: new_shipping_rate,
            shipping_info: shipping_info,
            cart_items: cart_items
        }
        const processShippingGuest = async (payload) => {
            try {
                const response = await api.post(`/tier1/payment/guest/process_shipping/${payment_intent}`, payload);
                console.log(response.data?.payment_info?.body?.id)
            } catch (err) {
                console.log(`Error: ${err.message}`);
            }
            navigate('/checkout/guest/payment')
        }
        const processShippingUser = async (payload) => {
            try {
                const response = await axiosPrivate.post(`/tier1/payment/user/process_shipping/${payment_intent}`, payload);
                console.log(response.data?.payment_info?.body?.id)
            } catch (err) {
                console.log(`Error: ${err.message}`);
            }
            navigate('/checkout/payment')
        }
        if (shipping_info == null) {
            alert('Please enter shipping information')
        }
        else {
            if (shipping_rate == null) {
                alert('Please select a shipping rate')
            }
            else {
                if (!auth.id) {
                    processShippingGuest(payload)
                }
                else {
                    processShippingUser(payload)
                }
            }
        }
    }

    return (
        <>
            {width >= 992 &&
                <Elements stripe={stripePromise} options={options}>
                    <div className="col-7">
                        <div className="d-flex flex-row justify-content-center" style={{ width: '100%' }}>
                            <h3 style={{ color: "black" }}>Shipping Details</h3>
                        </div>
                        <div className="d-flex flex-row justify-content-center" style={{ height: '100%', width: '100%' }}>
                            <div className="d-flex flex-column" style={{ width: '90%', height: '500px' }}>
                                <AddressElement options={{ mode: 'shipping' }} onChange={(event) => {
                                    if (event.complete) {
                                        set_shipping_info(event.value)
                                    }
                                }} />
                                {shipping_info && shipping_rate_list &&
                                    <div className="d-flex flex-row pt-3">
                                        <Form.Select aria-label="Default select example" onChange={(e) => set_shipping_rate(e.target.value)}>
                                            <option>Select shipping rate</option>
                                            {shipping_rate_list?.map(rate => (
                                                <option key={rate.rate_id} value={rate.rate_id}>{rate?.service_type}</option>
                                            ))}
                                        </Form.Select>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-5">
                        <Summary handleProceed={handleProceed} />
                    </div>
                </Elements>
            }
            {
                width < 992 &&
                <Elements stripe={stripePromise} options={options}>
                    <div className="d-flex-column" style={{ width: "90%" }}>
                        <div className="d-flex flex-row justify-content-center" style={{ width: '100%' }}>
                            <h3 style={{ color: "black" }}>Shipping Details</h3>
                        </div>
                        <div className="d-flex flex-row justify-content-center" style={{ height: '100%', width: '100%' }}>
                            <div className="d-flex flex-column" style={{ width: '90%', height: '90%' }}>
                                <AddressElement options={{ mode: 'shipping' }} onChange={(event) => {
                                    if (event.complete) {
                                        set_shipping_info(event.value)
                                    }
                                }} />
                                {shipping_info && shipping_rate_list &&
                                    <div className="d-flex flex-row pt-3">
                                        <Form.Select aria-label="Default select example" onChange={(e) => set_shipping_rate(e.target.value)}>
                                            <option>Select shipping rate</option>
                                            {shipping_rate_list?.map(rate => (
                                                <option key={rate.rate_id} value={rate.rate_id}>{rate?.service_type}</option>
                                            ))}
                                        </Form.Select>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <SummarySmall handleProceed={handleProceed} />
                </Elements >
            }

        </>
    )
}

export default ShippingInfo