import { Outlet } from "react-router-dom"
import { useStoreState, useStoreActions } from 'easy-peasy';

const Main = () => {

    const layout = useStoreState((state) => state.layout)
    const layout_height = useStoreState((state) => state.layout_height)
    const dropdown1 = useStoreState((state) => state.dropdown1)
    const set_dropdown1 = useStoreActions((actions) => actions.set_dropdown1);
    const handleCloseDropDown = (e) => {
        console.log(e)
        if (dropdown1 == true && e.target.className != "fa-sharp fa-solid fa-caret-down" && e.target.className != "thisDivList" && e.target.className != "ps-3 pe-3 list-group-item" && e.target.className != "list-group-flush list-group") {
            set_dropdown1(false)
        }
    }
    return (
        <>
            <div className="container-fluid px-0 pe-0 pb-0 mb-0" onClick={(e) => handleCloseDropDown(e)} style={{ height: `${layout_height}px`, backgroundColor: `${layout.color_background}` }}>
                <Outlet />
            </div>
        </>
    )
}

export default Main
