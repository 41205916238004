import React from 'react'
import { useEffect } from 'react';
import {  useStoreActions } from 'easy-peasy';
import { Outlet } from 'react-router-dom'

const GetLayout = () => {
    const get_customer_layout = useStoreActions((actions) => actions.get_customer_layout);
    
    useEffect(() => {
        get_customer_layout();
    }, [])

    return (
        <><Outlet /></>
    )
}

export default GetLayout