import React from 'react'
import { useStoreState } from 'easy-peasy';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
const DisplayText = ({ data }) => {

    const layout = useStoreState((state) => state.layout);

    return (
        <>
            
            {data?.style_type == "1" &&
                <div className="d-flex flex-row justify-content-center pt-2 mx-0" style={{ height: `${data?.height}px`, width: "100%", backgroundColor: `${layout.color_background}` }}>
                    <Card style={{ width: "90%", border: "hidden", backgroundColor: `${layout.color_background}` }}>
                        <div className="d-flex flex-row justify-content-around" style={{ height: "100%", width: "100%", backgroundColor: `${layout.color_background}` }}>
                            <div className="d-flex flex-row" style={{ height: "100%", width: "100%" }}>
                                <Card.Body style={{ width: "40%" }}>
                                    <Card.Title>{data?.title}{' '}{data?.style_type}</Card.Title>
                                    <Card.Text style={{ fontSize: "20px" }}>
                                        {data?.content1}
                                    </Card.Text>
                                </Card.Body>
                            </div>
                        </div>
                    </Card>
                </div>
            }
            {data?.style_type == "2" &&
                <div className="d-flex flex-column align-items-center" style={{ height: `${data?.height}px`, width: "100%", color: "black" }}>
                    <h1>{data?.title}{' '}{data?.style_type}</h1>
                    <div className="d-flex flex-row justify-content-center pt-2 mx-0" style={{ height: "100%", width: "90%", backgroundColor: `${layout.color_background}` }}>
                        <Card style={{ width: "100%", border: "hidden", backgroundColor: `${layout.color_background}` }}>
                            <div className="d-flex flex-row justify-content-around align-items-center" style={{ height: "100%", width: "100%", backgroundColor: `${layout.color_background}` }}>
                                <div className="d-flex flex-column align-items-center" style={{ height: "100%", width: "30%" }}>
                                    <img src={`https://${process.env.REACT_APP_IMAGE_DISTRO}/img/${data?.picture1}`} className="card-img-top" style={{ height: "50%", width: "50%", borderRadius: "100px" }} alt="..."></img>
                                    <Card.Body style={{ width: "60%", color: "black" }}>
                                        <Card.Title>{data?.title}</Card.Title>
                                        <Card.Text style={{ fontSize: "11px" }}>
                                            {data?.content1}
                                        </Card.Text>
                                    </Card.Body>
                                </div>
                                <div className="d-flex flex-column align-items-center" style={{ height: "100%", width: "30%" }}>
                                    <img src={`https://${process.env.REACT_APP_IMAGE_DISTRO}/img/${data?.picture1}`} className="card-img-top" style={{ height: "50%", width: "50%", borderRadius: "100px" }} alt="..."></img>
                                    <Card.Body style={{ width: "60%", color: "black" }}>
                                        <Card.Title>{data?.title}</Card.Title>
                                        <Card.Text style={{ fontSize: "11px" }}>
                                            {data?.content1}
                                        </Card.Text>
                                    </Card.Body>
                                </div>
                                <div className="d-flex flex-column align-items-center" style={{ height: "100%", width: "30%" }}>
                                    <img src={`https://${process.env.REACT_APP_IMAGE_DISTRO}/img/${data?.picture1}`} className="card-img-top" style={{ height: "50%", width: "50%", borderRadius: "100px" }} alt="..."></img>
                                    <Card.Body style={{ width: "60%", color: "black" }}>
                                        <Card.Title>{data?.title}</Card.Title>
                                        <Card.Text style={{ fontSize: "11px" }}>
                                            {data?.content1}
                                        </Card.Text>
                                    </Card.Body>
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
            }
            {data?.style_type == "3" &&
                <div className="d-flex flex-row justify-content-center pt-2 mx-0" style={{ height: `${data?.height}px`, width: "100%", backgroundColor: `${layout.color_background}` }}>
                    <Card style={{ width: "100%", border: "hidden", backgroundColor: `${layout.color_background}` }}>
                        <div className="d-flex flex-row justify-content-around" style={{ height: "100%", width: "100%", backgroundColor: `${layout.color_background}` }}>
                            <div className="d-flex flex-row" style={{ height: "100%", width: "40%" }}>
                                <Card.Body style={{ width: "40%" }}>
                                    <Card.Title>{data?.title}{' '}{data?.style_type}</Card.Title>
                                    <Card.Text style={{ fontSize: "20px" }}>
                                        {data?.content1}
                                    </Card.Text>
                                </Card.Body>
                            </div>
                            <img src={`https://${process.env.REACT_APP_IMAGE_DISTRO}/img/${data?.picture1}`} className="card-img-top" style={{ height: "90%", width: "12rem" }} alt="..."></img>
                        </div>
                    </Card>
                </div>
            }
            {data?.style_type == "4" &&
                <div className="d-flex flex-column align-items-center" style={{ height: `450px`, width: "100%", color: "black" }}>
                    <div className="d-flex flex-row justify-content-center pt-2 mx-0" style={{ height: "100%", width: "90%", backgroundColor: `${layout.color_background}` }}>
                        <Card style={{ width: "100%", border: "hidden", backgroundColor: `${layout.color_background}` }}>
                            <div className="d-flex flex-row justify-content-evenly" style={{ height: "100%", width: "100%", backgroundColor: `${layout.color_background}` }}>
                                <div className="d-flex flex-column align-items-center" style={{ height: "100%", width: "50%", color: "black" }}>
                                    <Card.Body style={{ width: "90%" }}>
                                        <Card.Title>{data?.title}{' '}{data?.style_type}</Card.Title>
                                        <Card.Text style={{ fontSize: "12px" }}>
                                            {data?.content1}
                                        </Card.Text>
                                        <Card.Text style={{ fontSize: "12px" }}>
                                            {data?.content1}
                                        </Card.Text>
                                    </Card.Body>
                                </div>
                                <img src={`https://${process.env.REACT_APP_IMAGE_DISTRO}/img/${data?.picture1}`} className="card-img-top" style={{ height: "100%", width: "50%" }} alt="..."></img>
                            </div>
                        </Card>
                    </div>
                </div>
            }
            {data?.style_type == "5" &&
                <div className="d-flex flex-column align-items-center" style={{ height: `${data?.height}px`, width: "100%", color: "black" }}>
                    <div className="d-flex flex-row justify-content-center pt-2 mx-0" style={{ height: "100%", width: "90%", backgroundColor: `${layout.color_background}` }}>
                        <Card style={{ width: "100%", border: "hidden", backgroundColor: `${layout.color_background}` }}>
                            <div className="d-flex flex-row justify-content-evenly" style={{ height: "100%", width: "100%", backgroundColor: `${layout.color_background}` }}>
                                <img src={`https://${process.env.REACT_APP_IMAGE_DISTRO}/img/${data?.picture1}`} className="card-img-top" style={{ height: "100%", width: "50%" }} alt="..."></img>
                                <div className="d-flex flex-column align-items-center" style={{ height: "100%", width: "50%", color: "black" }}>
                                    <Card.Body style={{ width: "90%" }}>
                                        <Card.Title>{data?.title}{' '}{data?.style_type}</Card.Title>
                                        <Card.Text style={{ fontSize: "16px" }}>
                                            {data?.content1}
                                        </Card.Text>
                                        <Card.Text style={{ fontSize: "16px" }}>
                                            {data?.content2}
                                        </Card.Text>
                                    </Card.Body>
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
            }
            {data?.style_type == "6" &&
                <div className="d-flex flex-row justify-content-center pt-2 mx-0" style={{ height: `${data?.height}px`, width: "100%", backgroundColor: `${layout.color_background}` }}>
                    <Card style={{ width: "100%", border: "hidden", height: "100%", backgroundColor: `${layout.color_background}` }}>
                        <div className="d-flex flex-row justify-content-around" style={{ height: "100%", width: "100%", backgroundColor: `${layout.color_background}` }}>
                            <div className="d-flex flex-row" style={{ height: "100%", width: "65%" }}>
                                <Card.Body style={{ width: "40%" }}>
                                    <Card.Title>{data?.title}{' '}{data?.style_type}</Card.Title>
                                    <Card.Text>
                                        {data?.content1}
                                    </Card.Text>
                                </Card.Body>
                            </div>
                            <img src={`https://${process.env.REACT_APP_IMAGE_DISTRO}/img/homepage2.png`} className="card-img-top" style={{ height: "90%", width: "12rem" }} alt="..."></img>
                        </div>
                    </Card>
                </div>
            }
            {data?.style_type == "7" &&
                <div className="d-flex flex-row justify-content-center py-2 mx-0" style={{ height: `${data?.height}px`, width: "100%", backgroundColor: `${layout.color_background}` }}>
                    <Card style={{ width: "100%", border: "hidden", height: "100%", backgroundColor: `${layout.color_background}` }}>
                        <div className="d-flex flex-row justify-content-around" style={{ height: "100%", width: "100%", backgroundColor: `${layout.color_background}` }}>
                            <div className="d-flex flex-row" style={{ height: "100%", width: "65%" }}>
                                <Card.Body style={{ width: "40%" }}>
                                    <Card.Title>{data?.title}{' '}{data?.style_type}</Card.Title>
                                    <Card.Text>
                                        {data?.content1}
                                    </Card.Text>
                                </Card.Body>
                            </div>
                            <img src={`https://${process.env.REACT_APP_IMAGE_DISTRO}/img/${data?.picture1}`} className="card-img-top" style={{ height: "90%", width: "12rem" }} alt="..."></img>
                        </div>
                    </Card>
                </div>
            }
            {data?.style_type == "8" &&
                <div className="d-flex flex-row justify-content-center py-2 mx-0" style={{ height: `${data?.height}px`, width: "100%", backgroundColor: `${layout.color_background}` }}>
                    <Card style={{ width: "90%", border: "hidden", height: "100%", backgroundColor: `${layout.color_background}` }}>
                        <Card.Img variant="top" src={`https://${process.env.REACT_APP_IMAGE_DISTRO}/img/${data?.picture1}`} />
                        <Card.Body>
                            <Card.Text>
                                {data?.content1}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
            }
            {data?.style_type == "9" &&
                <div className="d-flex flex-row justify-content-center py-2 mx-0" style={{ height: `${data?.height}px`, width: "100%", backgroundColor: `${layout.color_background}` }}>
                    <Card className="bg-dark text-white" style={{ width: '100%' }}>
                        <Card.Img src={`https://${process.env.REACT_APP_IMAGE_DISTRO}/img/package-1.jpg`} alt="Card image" style={{ height: '450px' }} />
                        <Card.ImgOverlay>
                            <Card.Title>{data?.title}</Card.Title>
                            <Card.Text>
                                {data?.content1}
                            </Card.Text>
                        </Card.ImgOverlay>
                    </Card>
                </div>
            }
            {data?.style_type == "10" &&
                <div className="d-flex flex-row justify-content-center py-2 mx-0" style={{ height: `${data?.height}px`, width: "100%", backgroundColor: `${layout.color_background}` }}>
                    <Card className="bg-dark text-white" style={{ width: '90%' }}>
                        <Card.Img src={`https://${process.env.REACT_APP_IMAGE_DISTRO}/img/package-1.jpg`} alt="Card image" style={{ height: '450px' }} />
                        <Card.ImgOverlay>
                            <Card.Title>{data?.title}</Card.Title>
                            <Card.Text>
                                {data?.content1}
                            </Card.Text>
                        </Card.ImgOverlay>
                    </Card>
                </div>
            }
            {data?.style_type == "11" &&
                <div className="d-flex flex-row justify-content-center py-2 mx-0" style={{ height: `${data?.height}px`, width: "100%", backgroundColor: `${layout.color_background}` }}>
                    <CardGroup style={{width: '90%'}} >
                        <Card>
                            <Card.Img variant="top" src={`https://${process.env.REACT_APP_IMAGE_DISTRO}/img/${data?.picture1}`} />
                            <Card.Body style={{ backgroundColor: `${layout.color_background}`}}>
                                <Card.Text>
                                    {data?.content1}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Img variant="top" src={`https://${process.env.REACT_APP_IMAGE_DISTRO}/img/${data?.picture2}`} />
                            <Card.Body style={{ backgroundColor: `${layout.color_background}`}}>
                                <Card.Text>
                                    {data?.content2}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Img variant="top" src={`https://${process.env.REACT_APP_IMAGE_DISTRO}/img/${data?.picture3}`} />
                            <Card.Body style={{ backgroundColor: `${layout.color_background}`}}>
                                <Card.Text>
                                    {data?.content3}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </CardGroup>
                </div>
            }
        </>
    )
}

export default DisplayText