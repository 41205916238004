import axios from '../api/axios';
import useAuth from './useAuth';


const useRefreshToken = () => {
    const { setAuth } = useAuth();
    const token = localStorage.getItem('refresh_token')
    const url = '/tier1/user/refresh'
    const payload = {
        token: token
    }

    const refresh = async () => {
        const response = await axios.post(url, payload);
        console.log(response.data)
        setAuth(response.data);
        return response.data.access_token;
    }
    return refresh;
};

export default useRefreshToken;
