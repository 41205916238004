import React from 'react'
import { useStoreState } from 'easy-peasy';
import CheckoutBanner from './CheckoutBanner';
import CheckoutBannerSmall from './CheckoutBannerSmall';
import CheckoutBannerTiny from './CheckoutBannerTiny';
import { Outlet } from 'react-router-dom';
import LoadingPage from '../utility/LoadingPage';

const Checkout = () => {

  const layout = useStoreState((state) => state.layout);
  const width = useStoreState((state) => state.width);

  return (
    <>
      {layout != 'loading' &&
        <div className="container-fluid p-0" style={{ height: "100%", width: "100%", maxWidth: "1000px", color: "black" }}>
          {width >= 992 &&
            <CheckoutBanner />
          }
          {width >= 435 && width < 992 &&
            <CheckoutBannerSmall />
          }
          {width < 435 &&
            <CheckoutBannerTiny />
          }
          <hr></hr>
          <div className="d-flex flex-row justify-content-center" style={{ height: "100%", width: "100%", alignItems: "top" }}>
            <div className="container-fluid p-0 m-0" style={{ height: "100%", width: "100%", color: "black" }}>
              <div className="d-flex flex-row justify-content-center" style={{ height: "100%", width: "100%", alignItems: "top" }}>
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      }
    </>

  )
}



export default Checkout