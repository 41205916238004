import React from 'react'
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Button, Card, CloseButton, Form } from 'react-bootstrap';
import useAuth from "../../hooks/useAuth";


const CartItemSmall = ({ data }) => {
    const { auth } = useAuth();
    let quantity_list = []
    for (let index = 2; index < 100; index++) {
        quantity_list.push(index)
    }
    const [quantity, set_quantity] = useState(data.quantity)
    const cart_items = useStoreState((state) => state.cart_items);
    const remove_from_cart = useStoreActions((actions) => actions.remove_from_cart);
    const set_cart_items = useStoreActions((actions) => actions.set_cart_items);
    const remove_from_cart_guest = useStoreActions((actions) => actions.remove_from_cart_guest);
    const change_quantity_from_cart = useStoreActions((actions) => actions.change_quantity_from_cart);
    const change_quantity_from_cart_guest = useStoreActions((actions) => actions.change_quantity_from_cart_guest);
    const price = data.unit_amount / 100


    useEffect(() => {
        for (let index = 0; index < cart_items.length; index++) {
            if (cart_items[index].id == data.id) {
                set_quantity(cart_items[index].quantity)
            }
        }
    }, [set_cart_items])

    const handleClose = () => {
        if (auth.id) {
            const payload = {
                id: auth?.id,
                ref: data.id
            }
            remove_from_cart(payload)
        }
        else {
            const payload = {
                id: 'guest',
                ref: data.id
            }
            remove_from_cart_guest(payload)
        }
    }
    const handleChangeQuantity = (quantity) => {
        set_quantity(quantity)
        if (auth.id) {
            const payload = {
                id: auth?.id,
                ref: data.id,
                quantity: quantity
            }
            change_quantity_from_cart(payload)
        }
        else {
            const payload = {
                ref: data.id,
                quantity: quantity
            }
            change_quantity_from_cart_guest(payload)
        }
    }
    return (

        <div className="d-flex flex-column justify-content-center align-items-center p-1" style={{ color: 'black', width: '100%' }} >
            <div className="d-flex flex-row align-items-end p-1" style={{ color: 'black', width: '100%' }} >
                <div className="d-flex flex-row" style={{ color: 'black', width: '100%', height: "10rem", borderRadius: '30px', border: '1px solid black' }}>
                    <div className="d-flex flex-column" style={{ color: 'black', width: '100%', height: '100%' }} >
                        <div className="d-flex flex-row" style={{ color: 'black', width: '100%', height: '100%' }}>
                            <div className='col-11'>
                                <div className="d-flex flex-row p-0 m-0" style={{ color: 'black', width: '100%', height: '100%' }} >
                                    <div className="d-flex flex-row align-items-center" style={{ color: 'black', width: '100%', height: '100%' }}>
                                        <div className="d-flex flex-column" style={{ width: '130px' }}>
                                            {data?.picture &&
                                                <>
                                                    <img src={`https://${process.env.REACT_APP_IMAGE_DISTRO}/img/${data?.picture}`} className="card-img-top ms-2" style={{ height: "9rem", width: "7rem", border: ` thin `, borderRadius: '30px' }} alt="..."></img>
                                                </>
                                            }
                                        </div>
                                        <div className="d-flex flex-column" style={{ width: '200px', color: 'black' }}>
                                            <h5 className="card-title">{data.title}</h5>
                                            <div className="d-flex flex-row align-items-center" style={{ color: 'black' }} >
                                                <h6 className='m-0'>Qty:</h6>
                                                <Form.Select size='sm' aria-label="Default select example" style={{ width: '70px' }} onChange={(e) => handleChangeQuantity(e.target.value)}>
                                                    <option value={quantity}>{quantity}</option>
                                                    {quantity != 1 &&
                                                        <option value={1}>{1}</option>
                                                    }
                                                    {quantity_list?.map(num => (
                                                        <option key={num} value={num}>{num}</option>
                                                    ))}
                                                </Form.Select>
                                            </div>
                                            <h6 className="card-text py-2"> $ {price.toFixed(2)}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-1'>
                                <div className="d-flex flex-row-reverse" style={{ color: 'black', height: '100%' }} >
                                    <CloseButton onClick={() => handleClose()} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default CartItemSmall