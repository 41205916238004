import { useRef, useState, useEffect } from 'react';
import useAuth from '../../hooks/useAuth';
import { useNavigate, useLocation } from 'react-router-dom';
import { useStoreState, useStoreActions } from 'easy-peasy';
import useInput from '../../hooks/useInput';
import useToggle from '../../hooks/useToggle';
import api from '../../api/axios';
import useLocalStorage from "../../hooks/useLocalStorage";


const Login = ({ allowedRoles }) => {
    const { setAuth } = useAuth();
    const navigate = useNavigate();

    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    const userRef = useRef();
    const errRef = useRef();
    const [errMsg, setErrMsg] = useState('');
    const [username, set_username] = useState('');
    const [password, setPassword] = useState('');
    const [user, reset_user, userAttribs] = useInput('user', '')
    const set_logged_user = useStoreActions((actions) => actions.set_logged_user);
    const update_cart = useStoreActions((actions) => actions.update_cart);
    const cart_items = useStoreState((state) => state.cart_items);
    const get_cart = useStoreActions((actions) => actions.get_cart);
    const [check, toggleCheck] = useToggle('persist', true);

    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [user, password])

    const handleSubmit = async (e) => {
        e.preventDefault();
        const credentials = { username: username, password: password };
        try {
            const response = await api.post('/tier1/user/login', credentials);
            console.log(response.data)
            setAuth(response.data);
            localStorage.setItem('refresh_token', JSON.stringify(response.data?.refresh_token))
            set_logged_user(response.data)
            if (cart_items && !response.data?.cart_items_ids) {
                const payload = {
                    id: response.data?.id,
                    items: cart_items
                }
                update_cart(payload)
            }
            else if (cart_items && response.data?.cart_items_ids) {
                /* create function for popup to let customer know that we are combining carts*/
                const payload = {
                    id: response.data?.id,
                    items: cart_items
                }
                update_cart(payload)
            }
            else {

                get_cart(response.data?.id)
            }
            reset_user()
            navigate(from);
            
        } catch (err) {
            console.log(`Error: ${err.message}`);
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.response?.status === 403) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Login Failed');
            }
            errRef.current.focus();
        }
    }
    return (
        <>
            <div className="container-fluid" style={{ height: "800px", backgroundColor: "#f2f2f2" }}>
                <main className="Home">
                    <div>
                        <div className='container-fluid' style={{ backgroundColor: "#f2f2f2", height: "100vh" }}>
                            <div className="login-page">
                                <div className="form-login">
                                    <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                                    <h1>Sign In</h1>
                                    <form className='login-form' onSubmit={handleSubmit}>
                                        <label htmlFor="username">Username:</label>
                                        <input
                                            type="text"
                                            id="username"
                                            ref={userRef}
                                            autoComplete="off"
                                            {...userAttribs}
                                            onChange={(e) => set_username(e.target.value)}
                                            value={username}
                                            required
                                        />
                                        <label htmlFor="password">Password:</label>
                                        <input
                                            type="password"
                                            id="password"
                                            onChange={(e) => setPassword(e.target.value)}
                                            value={password}
                                            required
                                        />
                                        <button>Sign In</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>

        </>
    )
}

export default Login