import React from 'react'
import { useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Outlet } from 'react-router-dom'

const Width = () => {

    const width = useStoreState((state) => state.width);
    const set_width = useStoreActions((actions) => actions.set_width);

    const updateDimensions = () => {
        const width = window.innerWidth
        set_width(width)
    }
    console.log(width)

    useEffect(() => {

        updateDimensions();
        window.addEventListener("resize", updateDimensions);

        return () =>
            window.removeEventListener("resize", updateDimensions);
    }, [])
    return (
        <><Outlet /></>
    )
}

export default Width