import React from 'react'
import { useStoreState } from 'easy-peasy';

const HeaderLogo = () => {
    const layout = useStoreState((state) => state.layout);
    return (
        <div className="d-flex flex-column">
            <img src={`https://${process.env.REACT_APP_IMAGE_DISTRO}/img/${layout?.logo}`} style={{ width: '290px', height: "110px" }} className="rounded-pill">
            </img>
        </div>
    )
}

export default HeaderLogo