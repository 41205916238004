import React from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStoreState, useStoreActions } from 'easy-peasy';
import useAuth from '../../hooks/useAuth';
import useLocalStorage from "../../hooks/useLocalStorage";
import api from '../../api/axios';

const Item = () => {

    const navigate = useNavigate();
    const { auth } = useAuth();
    const layout = useStoreState((state) => state.layout);
    const item = useStoreState((state) => state.item);
    const width = useStoreState((state) => state.width);
    const cart_items = useStoreState((state) => state.cart_items);
    const quantity = useStoreState((state) => state.quantity);
    const set_quantity = useStoreActions((actions) => actions.set_quantity);
    const set_item_check = useStoreActions((actions) => actions.set_item_check);
    const set_cart_items = useStoreActions((actions) => actions.set_cart_items);
    const add_to_cart = useStoreActions((actions) => actions.add_to_cart);
    const add_to_cart_guest = useStoreActions((actions) => actions.add_to_cart_guest);

    useEffect(() => {
        set_item_check(true)
        return () => {
            set_item_check(false)
        };
    }, [])

    const handleAddToCart = () => {

        const addToCartUser = async (payload) => {
            try {
                const response = await api.post(`/tier1/cart/user/${payload.id}/add`, payload.new_item);
                set_cart_items(response.data)
            } catch (err) {
                console.log(`Error: ${err.message}`);
            }
            navigate('/checkout/cart')
        }

        const addToCartGuest = async (payload) => {
            try {
                const response = await api.post(`/tier1/cart/guest/add`, payload.new_item);
                const newItem = response.data.product
                newItem.quantity = response.data.quantity
                let found = false
                let index = 0
                if (cart_items?.length > index) {
                    while (index < cart_items?.length && found == false) {
                        if (cart_items[index]?.id == newItem.id) {
                            cart_items[index].quantity = cart_items[index].quantity + newItem.quantity
                            set_cart_items([...cart_items]);
                            localStorage.setItem('cart_items', JSON.stringify([...cart_items]))
                            found = true
                        }
                        else {
                            console.log("no match")
                        }
                        index++
                    }
                    if (found == false) {
                        set_cart_items([...cart_items, newItem]);
                        localStorage.setItem('cart_items', JSON.stringify([...cart_items, newItem]))
                    }
                }
                else {
                    set_cart_items([...cart_items, newItem]);
                    localStorage.setItem('cart_items', JSON.stringify([...cart_items, newItem]))
                }
            } catch (err) {
                console.log(`Error: ${err.message}`);
            }
            navigate('/checkout/cart')
        }

        if (auth.id) {
            const payload = {
                id: auth?.id,
                new_item: {
                    id: item?.id,
                    quantity: quantity
                }
            }
            addToCartUser(payload)
        }
        else {
            const payload = {
                id: 'guest',
                new_item: {
                    id: item?.id,
                    quantity: quantity
                }
            }
            addToCartGuest(payload)
        }
    }

    return (
        <>
            {width >= 992 &&
                <div className="container-fluid p-0" style={{ height: "100%", width: "85%", color: "black" }}>
                    <div className="item-card pt-5">
                        <div className='row'>
                            <div className='col-6'>
                                <img
                                    src={`https://${process.env.REACT_APP_IMAGE_DISTRO}/img/${item?.picture}`}
                                    width={400}
                                    height={600}
                                    className="item-image"
                                />
                            </div>
                            <div className='col-6'>
                                <h1 className="item-name">{item?.title}</h1>
                                <div className="d-flex flex-row">
                                    <h6 className="align-self-center pe-3" >Price: </h6>
                                    <h3 className="item-price">${(item?.unit_amount / 100).toFixed(2)}</h3>
                                </div>
                                <label className='p-2' htmlFor="pageContent">Quantity:</label>
                                <input
                                    id="pageContent"
                                    type="text"
                                    required
                                    value={quantity}
                                    style={{ width: '25%' }}
                                    onChange={(e) => set_quantity(e.target.value)}
                                />
                                <button className='m-2' style={{ color: 'white', width: '70%', fontSize: '20px', backgroundColor: `${layout.color_main}`, height: "50px", border: ` solid ${layout.color_main}`, borderRadius: '50px' }} size='sm' onClick={() => handleAddToCart()}>Add to Cart</button>
                                <button className='m-2' style={{ width: '70%', color: 'white', fontSize: '20px', backgroundColor: `${layout.color_main}`, height: "50px", border: ` solid ${layout.color_main}`, borderRadius: '50px' }} size='sm' onClick={() => handleAddToCart()} >Buy Now</button>
                            </div>
                            <div className='pt-4'></div>
                            <hr></hr>
                            <div className='pt-3'></div>
                            <h5 className='pt-3'>PRODUCT DESCRPTION</h5>
                            <p className="item-description">{item?.description}</p>
                            <div className="d-flex flex-column" style={{ width: '100%', color: 'black' }}>
                                <div className="d-flex flex-row justify-content-evenly" style={{ width: '50%' }}>
                                    {item?.color && <><p className="item-color p-3">Color: {item?.color}</p></>}
                                    {item?.specs && <><p className="item-specs p-3">Specs: {item?.specs}</p></>}
                                    {item?.size && <><p className="item-size p-3">Size: {item?.size}</p></>}
                                    {item?.width && <><p className="item-width p-3">Width: {item?.width}</p></>}
                                    {item?.quantity && <><p className="quantity p-3">inventory: {item?.quantity}</p></>}
                                </div>
                                <div className="d-flex flex-row justify-content-evenly" style={{ width: '50%' }}>
                                    {item?.color && <><p className="item-color p-3">Color: {item?.color}</p></>}
                                    {item?.specs && <><p className="item-specs p-3">Specs: {item?.specs}</p></>}
                                    {item?.size && <><p className="item-size p-3">Size: {item?.size}</p></>}
                                    {item?.width && <><p className="item-width p-3">Width: {item?.width}</p></>}
                                    {item?.quantity && <><p className="quantity p-3">inventory: {item?.quantity}</p></>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {width < 992 &&
                <div className="container-fluid p-0" style={{ height: "100%", width: "85%", color: "black" }}>
                    <div className="item-card pt-5">
                        <div className="d-flex flex-column align-items-center pt-3" style={{ height: "100%" }} >
                           {width >= 454 && 
                            <img
                                src={`https://${process.env.REACT_APP_IMAGE_DISTRO}/img/${item?.picture}`}
                                width={400}
                                height={600}
                                className="item-image"
                            />
                            }
                           {width >= 385 && width < 454 &&
                            <img
                                src={`https://${process.env.REACT_APP_IMAGE_DISTRO}/img/${item?.picture}`}
                                width={330}
                                height={500}
                                className="item-image"
                            />
                            }
                           {width >= 319 && width < 385 &&
                            <img
                                src={`https://${process.env.REACT_APP_IMAGE_DISTRO}/img/${item?.picture}`}
                                width={264}
                                height={400}
                                className="item-image"
                            />
                            }
                           {width < 319 &&
                            <img
                                src={`https://${process.env.REACT_APP_IMAGE_DISTRO}/img/${item?.picture}`}
                                width={231}
                                height={350}
                                className="item-image"
                            />
                            }
                        </div>
                        <h1 className="item-name">{item?.title}</h1>
                        <div className="d-flex flex-row">
                            <h6 className="align-self-center pe-3" >Price: </h6>
                            <h3 className="item-price">${(item?.unit_amount / 100).toFixed(2)}</h3>
                        </div>
                        <label className='p-2' htmlFor="pageContent">Quantity:</label>
                        <input
                            id="pageContent"
                            type="text"
                            required
                            value={quantity}
                            style={{ width: '25%' }}
                            onChange={(e) => set_quantity(e.target.value)}
                        />
                        <button className='m-2' style={{ color: 'white', width: '70%', fontSize: '20px', backgroundColor: `${layout.color_main}`, height: "50px", border: ` solid ${layout.color_main}`, borderRadius: '50px' }} size='sm' onClick={() => handleAddToCart()}>Add to Cart</button>
                        <button className='m-2' style={{ width: '70%', color: 'white', fontSize: '20px', backgroundColor: `${layout.color_main}`, height: "50px", border: ` solid ${layout.color_main}`, borderRadius: '50px' }} size='sm' onClick={() => handleAddToCart()} >Buy Now</button>
                    </div>
                    <div className='pt-4'></div>
                    <hr></hr>
                    <div className='pt-3'></div>
                    <h5 className='pt-3'>PRODUCT DESCRPTION</h5>
                    <p className="item-description">{item?.description}</p>
                    <div className="d-flex flex-column" style={{ width: '100%', color: 'black' }}>
                        <div className="d-flex flex-row justify-content-evenly" style={{ width: '50%' }}>
                            {item?.color && <><p className="item-color p-3">Color: {item?.color}</p></>}
                            {item?.specs && <><p className="item-specs p-3">Specs: {item?.specs}</p></>}
                            {item?.size && <><p className="item-size p-3">Size: {item?.size}</p></>}
                            {item?.width && <><p className="item-width p-3">Width: {item?.width}</p></>}
                            {item?.quantity && <><p className="quantity p-3">inventory: {item?.quantity}</p></>}
                        </div>
                        <div className="d-flex flex-row justify-content-evenly" style={{ width: '50%' }}>
                            {item?.color && <><p className="item-color p-3">Color: {item?.color}</p></>}
                            {item?.specs && <><p className="item-specs p-3">Specs: {item?.specs}</p></>}
                            {item?.size && <><p className="item-size p-3">Size: {item?.size}</p></>}
                            {item?.width && <><p className="item-width p-3">Width: {item?.width}</p></>}
                            {item?.quantity && <><p className="quantity p-3">inventory: {item?.quantity}</p></>}
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default Item

