import React from 'react'
import { useEffect } from "react";
import { Outlet } from 'react-router-dom'
import { useStoreState, useStoreActions } from 'easy-peasy';
import useAuth from "../../hooks/useAuth";

const CheckCart = () => {

    const { auth } = useAuth();
    const cart_items = useStoreState((state) => state.cart_items);
    const get_cart = useStoreActions((actions) => actions.get_cart);
    const set_cart_items = useStoreActions((actions) => actions.set_cart_items);

    useEffect(() => {
        console.log(!auth?.id)
        if (!auth?.id) {
            if (JSON.parse(localStorage.getItem('cart_items'))){
                set_cart_items(JSON.parse(localStorage.getItem('cart_items')))
                console.log(JSON.parse(localStorage.getItem('cart_items')))
            }
            else {
                localStorage.setItem('cart_items', JSON.stringify(cart_items))
            }
        }
        else {
            get_cart(auth?.id)
        }
    }, [])

    return (
        <><Outlet /></>
    )
}

export default CheckCart