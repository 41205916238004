import { useRef, useState, useEffect } from "react";
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { faCheck, faTimes, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useStoreState, useStoreActions } from 'easy-peasy';
import useAuth from '../../hooks/useAuth';
import useInput from '../../hooks/useInput';
import useToggle from '../../hooks/useToggle';
import api from '../../api/axios';

const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;


const Register = () => {

    const { setAuth } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    const userRef = useRef();
    const errRef = useRef();
    const [validName, setValidName] = useState(false);
    const [userFocus, set_userFocus] = useState(false);
    const [validPwd, setValidPwd] = useState(false);
    const [pwdFocus, setPwdFocus] = useState(false);
    const [matchPwd, setMatchPwd] = useState('');
    const [validMatch, setValidMatch] = useState(false);
    const [matchFocus, setMatchFocus] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);
    const [check, toggleCheck] = useToggle('persist', true);
    const [user, reset_user, userAttribs] = useInput('user', '')
    
    const username = useStoreState((state) => state.username);
    const user_password = useStoreState((state) => state.user_password);
    const user_email = useStoreState((state) => state.user_email);
    const set_username = useStoreActions((actions) => actions.set_username);
    const set_user_password = useStoreActions((actions) => actions.set_user_password);
    const set_user_email = useStoreActions((actions) => actions.set_user_email);



    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setValidName(USER_REGEX.test(username));
    }, [username])

    useEffect(() => {
        setValidPwd(PWD_REGEX.test(user_password));
        setValidMatch(user_password === matchPwd);
    }, [user_password, matchPwd])

    useEffect(() => {
        setErrMsg('');
    }, [username, user_password, matchPwd])

    const handleSubmit = async (e) => {
        e.preventDefault();
        const userInfo = { username: username, password: user_password, email: user_email };
        // if button enabled with JS hack
        const v1 = USER_REGEX.test(username);
        const v2 = PWD_REGEX.test(user_password);
        if (!v1 || !v2) {
            setErrMsg("Invalid Entry");
            return;
        }
        try {
            const response = await api.post(`/tier1/user/register/${process.env.REACT_APP_UUID}`, userInfo);
            console.log(response.data)
            setSuccess(true);
            set_username('');
            set_user_email('');
            set_user_password('');
            setMatchPwd('');
            setAuth(response.data);
            localStorage.setItem('refresh_token', JSON.stringify(response.data?.refresh_token))
            reset_user()
            navigate(from, { replace: true });
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 409) {
                setErrMsg('Username Taken');
            } else {
                setErrMsg('Registration Failed')
            }
            errRef.current.focus();
        }
    }

    return (
        <div className="container-fluid" style={{ height: "800px", backgroundColor: "#f2f2f2" }}>
            <main className="Home">
                <div>
                    <div className='container-fluid' style={{ backgroundColor: "#f2f2f2" }}>
                        {success ? (
                            <section>
                                <h1>Success!</h1>
                                <p>
                                    <Link to="/login">Sign In</Link>
                                </p>
                            </section>
                        ) : (
                            <section>
                                <div className="login-page">
                                    <div className="form-login">
                                        <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                                        <h1>Register</h1>
                                        <form className='login-form' onSubmit={handleSubmit}>
                                            <label htmlFor="username">
                                                Username:
                                                <FontAwesomeIcon icon={faCheck} className={validName ? "valid" : "hide"} />
                                                <FontAwesomeIcon icon={faTimes} className={validName || !username ? "hide" : "invalid"} />
                                            </label>
                                            <input
                                                type="text"
                                                id="username"
                                                ref={userRef}
                                                autoComplete="off"
                                                onChange={(e) => set_username(e.target.value)}
                                                value={username}
                                                required
                                                aria-invalid={validName ? "false" : "true"}
                                                aria-describedby="uidnote"
                                                onFocus={() => set_userFocus(true)}
                                                onBlur={() => set_userFocus(false)}
                                            />
                                            <p id="uidnote" className={userFocus && username && !validName ? "instructions" : "offscreen"}>
                                                <FontAwesomeIcon icon={faInfoCircle} />
                                                4 to 24 characters.<br />
                                                Must begin with a letter.<br />
                                                Letters, numbers, underscores, hyphens allowed.
                                            </p>
                                            <label htmlFor="email">
                                                Email:
                                            </label>
                                            <input
                                                type="text"
                                                id="email"
                                                autoComplete="off"
                                                onChange={(e) => set_user_email(e.target.value)}
                                                value={user_email}
                                                required

                                            />

                                            <label htmlFor="password">
                                                Password:
                                                <FontAwesomeIcon icon={faCheck} className={validPwd ? "valid" : "hide"} />
                                                <FontAwesomeIcon icon={faTimes} className={validPwd || !user_password ? "hide" : "invalid"} />
                                            </label>
                                            <input
                                                type="password"
                                                id="password"
                                                onChange={(e) => set_user_password(e.target.value)}
                                                value={user_password}
                                                required
                                                aria-invalid={validPwd ? "false" : "true"}
                                                aria-describedby="pwdnote"
                                                onFocus={() => setPwdFocus(true)}
                                                onBlur={() => setPwdFocus(false)}
                                            />
                                            <p id="pwdnote" className={pwdFocus && !validPwd ? "instructions" : "offscreen"}>
                                                <FontAwesomeIcon icon={faInfoCircle} />
                                                8 to 24 characters.<br />
                                                Must include uppercase and lowercase letters, a number and a special character.<br />
                                                Allowed special characters: <span aria-label="exclamation mark">!</span> <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span> <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span>
                                            </p>


                                            <label htmlFor="confirm_pwd">
                                                Confirm Password:
                                                <FontAwesomeIcon icon={faCheck} className={validMatch && matchPwd ? "valid" : "hide"} />
                                                <FontAwesomeIcon icon={faTimes} className={validMatch || !matchPwd ? "hide" : "invalid"} />
                                            </label>
                                            <input
                                                type="password"
                                                id="confirm_pwd"
                                                onChange={(e) => setMatchPwd(e.target.value)}
                                                value={matchPwd}
                                                required
                                                aria-invalid={validMatch ? "false" : "true"}
                                                aria-describedby="confirmnote"
                                                onFocus={() => setMatchFocus(true)}
                                                onBlur={() => setMatchFocus(false)}
                                            />
                                            <p id="confirmnote" className={matchFocus && !validMatch ? "instructions" : "offscreen"}>
                                                <FontAwesomeIcon icon={faInfoCircle} />
                                                Must match the first password input field.
                                            </p>

                                            <button disabled={!validName || !validPwd || !validMatch ? true : false}>Sign Up</button>
                                        </form>
                                        <p>
                                            Already registered?<br />
                                            <span className="line">
                                                <Link to="/login">Sign In</Link>
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </section>
                        )}
                    </div>
                </div>
            </main>
        </div>
    )
}


export default Register