import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { AuthProvider } from './context/AuthProvider';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { StoreProvider } from 'easy-peasy'
import store from './store'
import 'bootstrap/dist/css/bootstrap.min.css';





ReactDOM.render(
    <React.StrictMode>
      <StoreProvider store={store}>
        <BrowserRouter>
          <AuthProvider>
            <Routes>
              <Route path="/*" element={<App />} />
            </Routes>
          </AuthProvider>
        </BrowserRouter>
      </StoreProvider>
    </React.StrictMode>,
  document.getElementById('root')
);