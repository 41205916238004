import React from 'react'
import { useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import { useStoreActions } from 'easy-peasy';

const Page = () => {
  const { page_name, category_name, subcategory_name, subcategory2_name, ref } = useParams();
  const get_customer_home = useStoreActions((actions) => actions.get_customer_home);
  const get_customer_page = useStoreActions((actions) => actions.get_customer_page);
  const get_customer_category = useStoreActions((actions) => actions.get_customer_category);
  const get_customer_subcategory = useStoreActions((actions) => actions.get_customer_subcategory);
  const get_customer_subcategory2 = useStoreActions((actions) => actions.get_customer_subcategory2);
  const get_customer_item = useStoreActions((actions) => actions.get_customer_item);

  useEffect(() => {
    let payload = {}
    if (ref) {
      get_customer_item(ref)
    }
    else if (subcategory2_name) {
      payload = {
        page_name: page_name,
        category_name: category_name,
        subcategory_name: subcategory_name,
        subcategory2_name: subcategory2_name,
      }
      get_customer_subcategory2(payload)
    }
    else if (subcategory_name) {
      payload = {
        page_name: page_name,
        category_name: category_name,
        subcategory_name: subcategory_name,
      }
      get_customer_subcategory(payload)
    }
    else if (category_name) {
      payload = {
        page_name: page_name,
        category_name: category_name,
      }
      get_customer_category(payload)
    }
    else if (page_name){
      payload = {
        page_name: page_name
      }
    get_customer_page(payload);
    }
    else{
    get_customer_home();
    }
    
  }, [page_name, category_name, subcategory_name, subcategory2_name, ref])

  return (
    <>
      <Outlet />
    </>
  )
}


export default Page