import React, { useState, useEffect } from 'react';
import { useStoreState } from 'easy-peasy';
import FooterLinks from './FooterLinks';
import FooterLinksSmall from './FooterLinksSmall';
import FooterStamp from './FooterStamp';

function Footer() {

    const layout = useStoreState((state) => state.layout);
    const width = useStoreState((state) => state.width);

    return (

        <>
            {width >= 992 &&
                <div className="container-fluid" style={{ backgroundColor: `${layout?.color_main}`, color: "#FFFFFF", height: "330px", bottom: "0" }} data-wow-delay="0.1s">
                    <div className="container">
                        <div className="col-md-12 text-center text-md-end">
                            <FooterLinks />
                            <FooterStamp />
                        </div>
                    </div>
                </div>
            }
            {width >= 768 && width < 992 &&
                <div className="container-fluid" style={{ backgroundColor: `${layout?.color_main}`, color: "#FFFFFF", height: "330px", bottom: "0" }} data-wow-delay="0.1s">
                    <div className="container">
                        <div className="col-md-12 text-center text-md-end">
                            <FooterLinks />
                            <FooterStamp />
                        </div>
                    </div>
                </div>
            }
            {width >= 493 && width < 768 &&
                <div className="container-fluid" style={{ backgroundColor: `${layout?.color_main}`, color: "#FFFFFF", height: "630px", bottom: "0" }} data-wow-delay="0.1s">
                    <div className="container">
                        <div className="col-md-12 text-center text-md-end">
                            <FooterLinksSmall />
                            <FooterStamp />
                        </div>
                    </div>
                </div>
            }
            {width < 493 &&
                <div className="container-fluid" style={{ backgroundColor: `${layout?.color_main}`, color: "#FFFFFF", height: "850px", bottom: "0" }} data-wow-delay="0.1s">
                    <div className="container">
                        <div className="col-md-12 text-center text-md-end">
                            <FooterLinksSmall />
                            <FooterStamp />
                        </div>
                    </div>
                </div>
            }
        </>


    )
}

export default Footer