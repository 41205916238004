import React from 'react';
import { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { PaymentElement } from '@stripe/react-stripe-js';
import LoadingPage from '../utility/LoadingPage';
import Summary from "./Summary";
import SummarySmall from "./SummarySmall";
import api from '../../api/axios';
import useLocalStorage from "../../hooks/useLocalStorage";

const stripePromise = loadStripe('pk_test_51MMvMaKzUCwJZcNBaRe1YRRgadCztXmrltaGf0Dpalr2jJyYQixZhQNxuFQAmwmnTPkzX9rg3PcfMzEASFpzazjQ00EK7wCSe2');

const Payment = () => {

    const [payment_intent] = useLocalStorage('payment_intent', null);
    const cart_items = useStoreState((state) => state.cart_items);
    const width = useStoreState((state) => state.width);
    const payment_complete = useStoreState((state) => state.payment_complete);
    const set_payment_complete = useStoreActions((actions) => actions.set_payment_complete);
    const set_payment_check = useStoreActions((actions) => actions.set_payment_check);
    const set_cart_banner_check = useStoreActions((actions) => actions.set_cart_banner_check);
    const set_details_banner_check = useStoreActions((actions) => actions.set_details_banner_check);
    const set_payment_banner_check = useStoreActions((actions) => actions.set_payment_banner_check);
    const set_shipping = useStoreActions((actions) => actions.set_shipping);
    const set_tax = useStoreActions((actions) => actions.set_tax);
    const clientSecret = useStoreState((state) => state.clientSecret);
    const [options, setoptions] = useState(null)

    useEffect(() => {
        let client_secret = null
        const payload = {
            uuid: `${process.env.REACT_APP_UUID}`,
            cart_items: cart_items
        }
        const getPaymentInfo = async (payload) => {
            try {
                const response = await api.post(`/tier1/payment/retrieve_intent_with_details/${payment_intent}`, payload);
                client_secret = response.data?.payment_info?.body?.client_secret
                set_shipping(response.data?.shipping_info)
                set_tax(response.data?.tax_info)
            } catch (err) {
                console.log(`Error: ${err.message}`);
            }
            if (client_secret != null) {
                setoptions({
                    clientSecret: client_secret,
                    appearance: {/*...*/ },
                })
            }
        }
        if (payment_intent != null) {
            getPaymentInfo(payload)
        }
        return () => {
            setoptions(null)
        };
    }, [payment_intent])

    useEffect(() => {
        set_payment_check(true)
        set_payment_banner_check(true)
        set_details_banner_check(true)
        set_cart_banner_check(true)
        return () => {
            set_payment_check(false)
            set_payment_banner_check(false)
            set_details_banner_check(false)
            set_cart_banner_check(false)
        };
    }, [])

    return (
        <>
            {options == null && <LoadingPage />}
            <>
                {width >= 992 &&
                    <>
                        {options != null &&
                            <Elements stripe={stripePromise} options={options}>
                                <div className="col-7">
                                    <div className="d-flex flex-row justify-content-center" style={{ width: '100%' }}>
                                        <h3 style={{ color: "black" }}>Payment Details</h3>
                                    </div>
                                    <div className="d-flex flex-row justify-content-center" style={{ height: '100%', width: '100%' }}>
                                        <div className="d-flex flex-column" style={{ width: '90%' }}>
                                            <PaymentElement />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-5">
                                    <Summary />
                                </div>
                            </Elements>
                        }
                    </>
                }
                {width < 992 &&

                    <>
                        {options != null &&
                            <Elements stripe={stripePromise} options={options}>
                                <div className="d-flex-column" style={{ width: "90%" }}>
                                    <div className="d-flex flex-row justify-content-center" style={{ width: '100%' }}>
                                        <h3 style={{ color: "black" }}>Payment Details</h3>
                                    </div>
                                    <div className="d-flex flex-row justify-content-center" style={{ height: '100%', width: '100%' }}>
                                        <div className="d-flex flex-column" style={{ width: '90%' }}>
                                            <PaymentElement />
                                        </div>
                                    </div>
                                </div>
                                <SummarySmall />
                            </Elements>

                        }
                    </>
                }
            </>
        </>
    )
};

export default Payment;