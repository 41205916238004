import { useState, useEffect, useRef } from 'react';
import DropdownSmall from './DropdownSmall';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Dropdown, Nav, DropdownButton, ListGroup, SplitButton } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const MenuItems = ({ items, depthLevel }) => {
    const layout = useStoreState((state) => state.layout);
    const [dropdown, setDropdown] = useState(false);
    let ref = useRef();
    useEffect(() => {
        const handler = (event) => {
            if (
                dropdown &&
                ref.current &&
                !ref.current.contains(event.target)
            ) {
                setDropdown(false);
            }
        };
        document.addEventListener('mousedown', handler);
        document.addEventListener('touchstart', handler);
        return () => {
            // Cleanup the event listener
            document.removeEventListener('mousedown', handler);
            document.removeEventListener('touchstart', handler);
        };
    }, [dropdown]);

    const onMouseEnter = () => {
        setDropdown(true);
    };

    const onMouseLeave = () => {
        setDropdown(false);
    };

    const closeDropdown = () => {
        dropdown && setDropdown(false);
    };

    return (
        <div
            ref={ref}
            // onMouseEnter={onMouseEnter}
            // onMouseLeave={onMouseLeave}
            // onClick={closeDropdown}
            className='thisDivList'
        >
            {items?.name && items?.categories?.length ? (

                <>
                    <ListGroup.Item
                        aria-haspopup="menu"
                        aria-expanded={dropdown ? 'true' : 'false'}
                        className='ps-3 pe-3'
                        style={{ color: 'white', backgroundColor: `${layout?.color_main}` }}>
                        <div className="d-flex flex-row justify-content-between align-items-center pt-3" style={{ height: "100%" }} >
                            <Nav.Link className='ps-2 py-1' style={{ color: "white", fontWeight: 'bold', fontSize: '25px' }} href={`/${items?.name}`}>{items.title}</Nav.Link>
                            <i onClick={() => setDropdown((prev) => !prev)} style={{ color: 'white' }} class="fa-sharp fa-solid fa-caret-down"></i>
                        </div>
                    </ListGroup.Item>
                    <DropdownSmall
                        depthLevel={depthLevel}
                        submenus={items?.categories}
                        dropdown={dropdown}
                    />
                </>
            ) : items?.name && items?.categories && items.categories?.length == 0 && items.page_type == "home" ? (
                <>
                    <ListGroup.Item
                        aria-haspopup="menu"
                        aria-expanded={dropdown ? 'true' : 'false'}
                        className='ps-3 pe-3'
                        style={{ color: 'white', backgroundColor: `${layout?.color_main}` }}>
                        <div className="d-flex flex-row justify-content-start align-items-center pt-3" style={{ height: "100%" }} >
                            <Nav.Link className='ps-2 py-1' style={{ color: "white", fontWeight: 'bold', fontSize: '25px' }} href={`/`}>{items.title}</Nav.Link>
                        </div>
                    </ListGroup.Item>
                </>
            ) : items?.name && items?.categories && items.categories?.length == 0 && items.page_type != "home" ? (
                <>
                    <ListGroup.Item
                        aria-haspopup="menu"
                        aria-expanded={dropdown ? 'true' : 'false'}
                        className='ps-3 pe-3'
                        style={{ color: 'white', backgroundColor: `${layout?.color_main}` }}>
                        <div className="d-flex flex-row justify-content-start align-items-center pt-3" style={{ height: "100%" }} >
                            <Nav.Link className='ps-2 py-1' style={{ color: "white", fontWeight: 'bold', fontSize: '25px' }} href={`/${items?.name}`}>{items.title}</Nav.Link>
                        </div>
                    </ListGroup.Item>
                </>
            ) : items?.name && items?.subcategories?.length ? (
                <>
                    <ListGroup.Item
                        aria-haspopup="menu"
                        aria-expanded={dropdown ? 'true' : 'false'}
                        className='ps-3 pe-3'
                        style={{ color: 'white', backgroundColor: `${layout?.color_main}` }}>
                        <div className="d-flex flex-row justify-content-between align-items-center pt-3" style={{ height: "100%" }} >
                            <Nav.Link className='ps-3 py-1' style={{ color: "white", fontWeight: 'bolder', fontSize: '20px' }} href={`/${items?.page?.name}/${items?.name}`}>{items.title}</Nav.Link>
                            <i onClick={() => setDropdown((prev) => !prev)} style={{ color: 'white' }} class="fa-sharp fa-solid fa-caret-down"></i>
                        </div>
                    </ListGroup.Item>
                    <DropdownSmall
                        depthLevel={depthLevel}
                        submenus={items?.subcategories}
                        dropdown={dropdown}
                    />
                </>
            ) : items?.name && items?.subcategories && items.subcategories?.length == 0 ? (
                <>
                    <ListGroup.Item
                        aria-haspopup="menu"
                        aria-expanded={dropdown ? 'true' : 'false'}
                        className='ps-3 pe-3'
                        style={{ color: 'white', backgroundColor: `${layout?.color_main}` }}>
                        <div className="d-flex flex-row justify-content-start align-items-center pt-3" style={{ height: "100%" }} >
                            <Nav.Link className='ps-3 py-1' style={{ color: "white", fontWeight: 'bolder', fontSize: '20px' }} href={`/${items?.page?.name}/${items?.name}`}>{items.title}</Nav.Link>
                        </div>
                    </ListGroup.Item>
                </>
            ) : items?.name && items?.subcategories2?.length ? (
                <>
                    <ListGroup.Item
                        aria-haspopup="menu"
                        aria-expanded={dropdown ? 'true' : 'false'}
                        className='ps-3 pe-3'
                        style={{ color: 'white', backgroundColor: `${layout?.color_main}` }}>
                        <div className="d-flex flex-row justify-content-between align-items-center pt-3" style={{ height: "100%" }} >
                            <Nav.Link className='ps-4 py-1' style={{ color: "white", fontWeight: 'bolder', fontSize: '15px' }} href={`/${items?.page?.name}/${items?.category?.name}/${items?.name}`}>{items.title}</Nav.Link>
                            <i onClick={() => setDropdown((prev) => !prev)} style={{ color: 'white' }} class="fa-sharp fa-solid fa-caret-down"></i>
                        </div>
                    </ListGroup.Item>
                    <DropdownSmall
                        depthLevel={depthLevel}
                        submenus={items?.subcategories2}
                        dropdown={dropdown}
                    />
                </>
            ) : items?.name && items?.subcategories2 && items.subcategories2?.length == 0 ? (
                <>
                    <ListGroup.Item
                        aria-haspopup="menu"
                        aria-expanded={dropdown ? 'true' : 'false'}
                        className='ps-3 pe-3'
                        style={{ color: 'white', backgroundColor: `${layout?.color_main}` }}>
                        <div className="d-flex flex-row justify-content-start align-items-center pt-3" style={{ height: "100%" }} >
                            <Nav.Link className='ps-4 py-1' style={{ color: "white", fontWeight: 'bolder', fontSize: '15px' }} href={`/${items?.page?.name}/${items?.category?.name}/${items?.name}`}>{items.title}</Nav.Link>
                        </div>
                    </ListGroup.Item>
                </>
            ) : (
                <ListGroup.Item className='ps-4 pe-0' style={{ color: 'white', backgroundColor: `${layout?.color_main}` }}>
                    <Nav.Link className='ps-5 py-1' style={{ color: "white", fontWeight: 'bolder', fontSize: '10px' }} href={`/${items?.page?.name}/${items?.category?.name}/${items?.subcategory?.name}/${items?.name}`}>{items.title}</Nav.Link>
                </ListGroup.Item>
            )}
        </div>
    );
};

export default MenuItems;