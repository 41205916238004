import React from 'react'
import { useState, useEffect } from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { Card } from 'react-bootstrap'
import { faGlasses } from '@fortawesome/free-solid-svg-icons';

const Summary = ({ handleProceed }) => {

  const layout = useStoreState((state) => state.layout);
  const cart_items = useStoreState((state) => state.cart_items);
  const set_cart_items = useStoreActions((actions) => actions.set_cart_items);
  const get_cart_total = useStoreState((state) => state.get_cart_total);
  const payment_check = useStoreState((state) => state.payment_check);
  const shipping = useStoreState((state) => state.shipping);
  const tax = useStoreState((state) => state.tax);
  const [displayTotal, setdisplayTotal] = useState(get_cart_total / 100)
  const [displayGrandTotal, setdisplayGrandTotal] = useState((get_cart_total / 100) + shipping)
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    setdisplayTotal(get_cart_total / 100)
    setdisplayGrandTotal((get_cart_total / 100) + shipping + tax)
  }, [cart_items, set_cart_items, get_cart_total, shipping, tax])

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${process.env.REACT_APP_RETURN_URL}/checkout/confirmation`,
      },
    });


    if (error) {
      setErrorMessage(error.message);
    } else {
    }
  };

  return (
    <div className="d-flex flex-row ps-5" style={{ height: "100%", maxWidth: '600px', width: "100%" }}>
      <Card style={{ width: "100%", height: "22rem", borderRadius: '50px' }}>
        <div className="d-flex flex-row justify-content-center" style={{ height: "70px", width: "100%", alignItems: "top" }}>
          <h5 className='pt-2' style={{ color: 'black' }}>Order Summary</h5>
        </div>
        <div className="d-flex flex-row justify-content-between" style={{ height: '40px' }}>
          <h5 className='pt-1 ps-3' style={{ color: 'black' }}>Subtotal</h5>
          <h5 className='pt-1 pe-5' style={{ color: 'black' }}>${displayTotal.toFixed(2)}</h5>
        </div>
        <div className="d-flex flex-row justify-content-between" style={{ height: '40px' }}>
          <h5 className='pt-1 ps-3' style={{ color: 'black' }}>Shipping</h5>
          <h5 className='pt-1 pe-5' style={{ color: 'black' }}>${shipping.toFixed(2)}</h5>
        </div>
        <div className="d-flex flex-row justify-content-between" style={{ height: '40px' }}>
          <h5 className='pt-1 ps-3' style={{ color: 'black' }}>Tax</h5>
          <h5 className='pt-1 pe-5' style={{ color: 'black' }}>${tax.toFixed(2)}</h5>
        </div>
        <hr></hr>
        <div className="d-flex flex-row justify-content-between" style={{ height: '40px' }}>
          <h4 className='pt-1 ps-3' style={{ color: 'black' }}>Grand Total</h4>
          <h5 className='pt-1 pe-5' style={{ color: 'black' }}>${displayGrandTotal.toFixed(2)}</h5>
        </div>
        <div className="d-flex flex-row align-items-center justify-content-center pt-2" style={{ height: '70px' }}>
          {payment_check == false &&
            <button className='p-0 m-0' style={{ width: '70%', fontSize: '20px', backgroundColor: `${layout.color_main}`, height: "40px", border: ` solid ${layout.color_main}`, borderRadius: '50px' }} size='sm' onClick={() => handleProceed()}>Proceed</button>
          }
          {payment_check == true &&
            <>
              <button disabled={!stripe} className='p-0 m-3' style={{ width: '70%', fontSize: '20px', backgroundColor: `${layout.color_main}`, height: "40px", border: ` solid ${layout.color_main}`, borderRadius: '50px' }} size='sm' onClick={(e) => handleSubmit(e)}>Submit</button>
            </>
          }
        </div>
        {errorMessage &&
          <div className="d-flex flex-row align-items-center justify-content-center" >
            <div style={{color: 'black'}}>{errorMessage}</div>
          </div>
        }
      </Card>
    </div>
  )
}

export default Summary