import React from 'react'
import { useEffect } from 'react';
import { Outlet, useParams } from "react-router-dom";
import { useStoreState, useStoreActions } from 'easy-peasy';
import ListItems from "./ListItems";
import ListTexts from "./ListTexts";
import LoadingPage from '../utility/LoadingPage';

const SubCategory = () => {

  const { subcategory2_name, ref } = useParams();
  const page = useStoreState((state) => state.page);
  const subcategory = useStoreState((state) => state.subcategory);
  const set_subcategory_check = useStoreActions((actions) => actions.set_subcategory_check);

  useEffect(() => {
    set_subcategory_check(true)
    return () => {
      set_subcategory_check(false)
    };
  }, [])

  return (
    <>
      {subcategory == 'loading' && <LoadingPage />}
      <>
        {subcategory != 'loading' &&
          <>
            {ref && <Outlet />}
            {!ref && 
              <>
                {subcategory2_name && <Outlet />}
                {!subcategory2_name &&
                  <>
                    <div className="container-fluid p-0" style={{ height: "100%", width: "100%", color: "black" }}>
                      <div className="d-flex flex-row justify-content-center pt-3" style={{ height: "100%" }} >
                        {page.page_type == "text" &&
                          <div className="container-fluid p-0" style={{ height: "100%" }}>
                            <div className="d-flex flex-row justify-content-center mb-3">
                              <h3 style={{ color: "black" }}>{subcategory?.title}</h3>
                            </div>
                            <ListTexts texts={subcategory?.texts} />
                          </div>
                        }
                        {page.page_type == "product" &&
                          <>
                            <div className="container-fluid p-0" style={{ height: "100%", width: "100%" }}>
                              <div className="d-flex flex-row justify-content-center">
                                <h3 style={{ color: "black" }}>{subcategory?.title}</h3>
                              </div>
                              <ListItems data={subcategory?.products} />
                            </div>
                          </>
                        }
                      </div>
                    </div>
                  </>
                }
              </>
            }
          </>
        }
      </>
    </>
  )
}
export default SubCategory