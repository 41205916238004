import { useStoreState, useStoreActions } from 'easy-peasy';
import { useState, useEffect } from 'react';
import useAxiosFetch from '../../hooks/useAxiosFetch';
import Display from './Display';
import Pagination from 'react-bootstrap/Pagination';
import api from '../../api/axios';

const ListTexts = ({ texts }) => {

    return (

        <div style={{ height: "95%" }}>
            <main className="Home" style={{ height: "100%" }}>
                <div className="d-flex flex-column align-items-center" style={{ height: "90%" }}>
                    {(texts?.length ? <Display data={texts} /> : <p className="statusMsg">Nothing to display.</p>)}
                </div>
            </main>
        </div>


    )
}

export default ListTexts
