import axios from 'axios';
//const BASE_URL = 'https://api.djthedeveloper.com:444';
//const BASE_URL = 'http://localhost:8000';
const BASE_URL = `${process.env.REACT_APP_API_URL}`;

export default axios.create({
    baseURL: BASE_URL
});

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true
});

export const axiosPayments = axios.create({
    baseURL: BASE_URL,
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ items: [{ id: "xl-tshirt" }] }),
});