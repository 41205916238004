import React from 'react'
import { useNavigate } from 'react-router-dom';

const Success = () => {
    const navigate = useNavigate();
    function refreshPage() {
        navigate(-1);
      }
      return (
        <div>
          <h1>Update Succesful!</h1>
          <button onClick={refreshPage}>Finish</button>
        </div>
      );
    }

export default Success