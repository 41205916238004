import React from 'react'
import { useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import { useStoreState, useStoreActions } from 'easy-peasy';
import ListItems from "./ListItems";
import ListTexts from "./ListTexts";
import LoadingPage from '../utility/LoadingPage';

const SubContent2 = () => {

  const { ref } = useParams();
  const page = useStoreState((state) => state.page); 
  const subcategory2 = useStoreState((state) => state.subcategory2);
  const set_subcategory2_check = useStoreActions((actions) => actions.set_subcategory2_check);

  useEffect(() => {
    set_subcategory2_check(true)
    return () => {
      set_subcategory2_check(false)
    };
  }, [])

  return (
    <>
      {subcategory2 == 'loading' && <LoadingPage />}
      <>
        {subcategory2 != 'loading' &&
          <>
            {ref && <Outlet />}
            {!ref && 
              <>
                <div className="container-fluid p-0" style={{ height: "100%", width: "100%", color: "black" }}>
                  <div className="d-flex flex-row justify-content-center pt-3" style={{ height: "100%" }} >
                    {page.page_type == "text" &&
                      <div className="container-fluid p-0" style={{ height: "100%" }}>
                        <div className="d-flex flex-row justify-content-center mb-3">
                          <h3 style={{ color: "black" }}>{subcategory2?.title}</h3>
                        </div>
                        <ListTexts texts={subcategory2?.texts} />
                      </div>
                    }
                    {page.page_type == "product" &&
                      <>
                        <div className="container-fluid p-0" style={{ height: "100%", width: "100%" }}>
                          <div className="d-flex flex-row justify-content-center">
                            <h3 style={{ color: "black" }}>{subcategory2?.title} </h3>
                          </div>
                          <ListItems data={subcategory2?.products} />
                        </div>
                      </>
                    }
                  </div>
                </div>
              </>
            }
          </>
        }

      </>
    </>
  )
}
export default SubContent2