import { Outlet } from "react-router-dom"
import { useStoreState } from 'easy-peasy';
import Header from "./Header"
import Footer from "./Footer"
import LoadingPage from '../utility/LoadingPage';

const Layout = () => {

    const layout = useStoreState((state) => state.layout);

    const height = useStoreState((state) => state.height)

    return (
        <>
            {layout == 'loading' &&
                <LoadingPage />

            }
            {!layout != 'loading' &&
                <div className="container-fluid px-0 pe-0 pb-0 mb-0" style={{ height: "100%", backgroundColor: `${layout.color_main}` }}>
                    <Header />
                    <div className="container-fluid pt-0 px-0 pe-0 pb-0 mb-0" style={{ height: `${height}px`, width: '100%', backgroundColor: '#f2f2f2' }}>
                        <Outlet />
                    </div>
                    <Footer />
                </div>
            }
        </>
    )
}

export default Layout
