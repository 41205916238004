import NavMenu from './NavMenu'
import NavMenuSmall from './NavMenuSmall'
import { useStoreState } from 'easy-peasy';
import HeaderLogo from './HeaderLogo';
import AuthButtons from './AuthButtons';
import AuthButtonsSmall from './AuthButtonsSmall';
import HeaderLogoSmall from './HeaderLogoSmall';


const Header = () => {

  const layout = useStoreState((state) => state.layout);
  const width = useStoreState((state) => state.width);

  return (
    <>

      {width >= 992 &&
        <>
          <div className="container-fluid d-none d-lg-block" style={{ height: "120px", backgroundColor: "#f2f2f2", top: "0px" }}>
            <div className="d-flex flex-row justify-content-between">
              <HeaderLogo />
              <AuthButtons />
            </div>
          </div>
          <NavMenu />
        </>

      }
      {width < 992 &&
        <>
          <div className="container-fluid p-0 pb-2 mb-1" style={{ height: "90px", backgroundColor: "#f2f2f2", top: "0px" }}>
            <div className="d-flex flex-row justify-content-between align-items-center px-2" style={{height: "100%"}}>
              <NavMenuSmall />
              <HeaderLogoSmall />
              <AuthButtonsSmall />
            </div>
          </div>
        </>
      }

    </>

  )
}

export default Header