import React from 'react'

const FooterStamp = () => {

    return (
        <div className="d-flex flex-row justify-content-center" style={{ position: "relative", top: "70px", height: "40px" }}>
            &copy; <a className="text-light pe-1" style={{ textDecoration: "none" }} href="#"> Your Site Name, All Right Reserved. Designed by</a> <a className="text-light pb-5" style={{ textDecoration: "none" }} href="https://htmlcodex.com">Darin Lassiter</a>
        </div>
    )
}

export default FooterStamp