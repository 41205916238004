import React from 'react'
import Card from 'react-bootstrap/Card';

const DisplayTextSmall = ({ data }) => {

    return (
        <>
            {data?.style_type == "1" &&
                <div className="d-flex flex-row justify-content-center pt-2 mx-0" style={{ height: `${data?.height_small}px`, width: "100%", backgroundColor: "#F2F2F2" }}>
                    <div className="d-flex flex-row justify-content-center" style={{ height: "100%", width: "100%", backgroundColor: "#F2F2F2" }}>
                        <div className="d-flex flex-row" style={{ height: "100%", width: "90%" }}>
                            <Card.Body style={{ width: "40%", color: "black" }}>
                                <Card.Title>{data?.title}{' '}{data?.style_type}</Card.Title>
                                <Card.Text style={{ fontSize: "20px" }}>
                                    {data?.content1}
                                </Card.Text>
                            </Card.Body>
                        </div>
                    </div>
                </div>
            }
            {data?.style_type == "2" &&
                <div className="d-flex flex-column align-items-center" style={{ height: `${data?.height_small}px`, width: "100%", color: "black" }}>
                    <h1>{data?.title}{' '}{data?.style_type}</h1>
                    <div className="d-flex flex-row justify-content-center pt-2 mx-0" style={{ height: "100%", width: "100%", backgroundColor: "#F2F2F2" }}>
                        <div className="d-flex flex-column justify-content-around align-items-center" style={{ height: "100%", width: "90%", backgroundColor: "#F2F2F2" }}>
                            <div className="d-flex flex-column align-items-center" style={{ height: "80%", width: "100%" }}>
                                <img src={`https://${process.env.REACT_APP_IMAGE_DISTRO}/img/${data?.picture1}`} className="card-img-top" style={{ height: "150px", width: "150px", borderRadius: "100px" }} alt="..."></img>
                                <Card.Body style={{ width: "100%", color: "black" }}>
                                    <Card.Text style={{ fontSize: "11px" }}>
                                        {data?.content1}
                                    </Card.Text>
                                </Card.Body>
                            </div>
                            <div className="d-flex flex-column align-items-center" style={{ height: "80%", width: "100%" }}>
                                <img src={`https://${process.env.REACT_APP_IMAGE_DISTRO}/img/${data?.picture2}`} className="card-img-top" style={{ height: "150px", width: "150px", borderRadius: "100px" }} alt="..."></img>
                                <Card.Body style={{ width: "100%", color: "black" }}>
                                    <Card.Text style={{ fontSize: "11px" }}>
                                        {data?.content2}
                                    </Card.Text>
                                </Card.Body>
                            </div>
                            <div className="d-flex flex-column align-items-center" style={{ height: "80%", width: "100%" }}>
                                <img src={`https://${process.env.REACT_APP_IMAGE_DISTRO}/img/${data?.picture3}`} className="card-img-top" style={{ height: "150px", width: "150px", borderRadius: "100px" }} alt="..."></img>
                                <Card.Body style={{ width: "100%", color: "black" }}>
                                    <Card.Text style={{ fontSize: "11px" }}>
                                        {data?.content3}
                                    </Card.Text>
                                </Card.Body>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {data?.style_type == "3" &&
                <div className="d-flex flex-column align-items-center" style={{ height: `${(data?.height_small)}px`, width: "100%", color: "black" }}>
                    <div className="d-flex flex-row justify-content-center pt-2 mx-0" style={{ height: "100%", width: "100%", backgroundColor: "#F2F2F2" }}>
                        <div className="d-flex flex-column align-items-center" style={{ height: "100%", width: "90%", backgroundColor: "#F2F2F2" }}>
                            <div className="d-flex flex-column align-items-center" style={{ height: "60%", width: "100%", color: "black" }}>
                                <Card.Body style={{ width: "100%" }}>
                                    <Card.Title>{data?.title}{' '}{data?.style_type}</Card.Title>
                                    <Card.Text style={{ fontSize: "11px" }}>
                                        {data?.content1}
                                    </Card.Text>
                                    <Card.Text style={{ fontSize: "11px" }}>
                                        {data?.content2}
                                    </Card.Text>
                                </Card.Body>
                            </div>
                            <img src={`https://${process.env.REACT_APP_IMAGE_DISTRO}/img/${data?.picture1}`} className="card-img-top" style={{ height: "40%", width: "70%" }} alt="..."></img>
                        </div>
                    </div>
                </div>
            }
            {data?.style_type == "4" &&
                <div className="d-flex flex-column align-items-center" style={{ height: `${(data?.height_small)}px`, width: "100%", color: "black" }}>
                    <div className="d-flex flex-row justify-content-center pt-2 mx-0" style={{ height: "100%", width: "100%", backgroundColor: "#F2F2F2" }}>
                        <div className="d-flex flex-column align-items-center" style={{ height: "100%", width: "90%", backgroundColor: "#F2F2F2" }}>
                            <div className="d-flex flex-column align-items-center" style={{ height: "60%", width: "100%", color: "black" }}>
                                <Card.Body style={{ width: "100%" }}>
                                    <Card.Title>{data?.title}{' '}{data?.style_type}</Card.Title>
                                    <Card.Text style={{ fontSize: "11px" }}>
                                        {data?.content1}
                                    </Card.Text>
                                    <Card.Text style={{ fontSize: "11px" }}>
                                        {data?.content2}
                                    </Card.Text>
                                </Card.Body>
                            </div>
                            <img src={`https://${process.env.REACT_APP_IMAGE_DISTRO}/img/${data?.picture1}`} className="card-img-top" style={{ height: "40%", width: "70%" }} alt="..."></img>
                        </div>
                    </div>
                </div>
            }
            {data?.style_type == "5" &&
                <div className="d-flex flex-column align-items-center" style={{ height: `${(data?.height_small)}px`, width: "100%", color: "black" }}>
                    <div className="d-flex flex-row justify-content-center pt-2 mx-0" style={{ height: "100%", width: "100%", backgroundColor: "#F2F2F2" }}>
                        <div className="d-flex flex-column align-items-center" style={{ height: "100%", width: "90%", backgroundColor: "#F2F2F2" }}>
                            <img src={`https://${process.env.REACT_APP_IMAGE_DISTRO}/img/${data?.picture1}`} className="card-img-top" style={{ height: "40%", width: "70%" }} alt="..."></img>
                            <div className="d-flex flex-column align-items-center" style={{ height: "60%", width: "100%", color: "black" }}>
                                <Card.Body style={{ width: "100%" }}>
                                    <Card.Title>{data?.title}{' '}{data?.style_type}</Card.Title>
                                    <Card.Text style={{ fontSize: "11px" }}>
                                        {data?.content1}
                                    </Card.Text>
                                    <Card.Text style={{ fontSize: "11px" }}>
                                        {data?.content2}
                                    </Card.Text>
                                </Card.Body>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {data?.style_type == "6" &&
                <div className="d-flex flex-column align-items-center" style={{ height: `${(data?.height_small)}px`, width: "100%", color: "black" }}>
                    <div className="d-flex flex-row justify-content-center pt-2 mx-0" style={{ height: "100%", width: "100%", backgroundColor: "#F2F2F2" }}>
                        <div className="d-flex flex-column align-items-center" style={{ height: "100%", width: "90%", backgroundColor: "#F2F2F2" }}>
                            <div className="d-flex flex-column align-items-center" style={{ height: "60%", width: "100%", color: "black" }}>
                                <Card.Body style={{ width: "100%" }}>
                                    <Card.Title>{data?.title}{' '}{data?.style_type}</Card.Title>
                                    <Card.Text style={{ fontSize: "11px" }}>
                                        {data?.content1}
                                    </Card.Text>
                                    <Card.Text style={{ fontSize: "11px" }}>
                                        {data?.content2}
                                    </Card.Text>
                                </Card.Body>
                            </div>
                            <img src={`https://${process.env.REACT_APP_IMAGE_DISTRO}/img/${data?.picture1}`} className="card-img-top" style={{ height: "40%", width: "70%" }} alt="..."></img>
                        </div>
                    </div>
                </div>
            }
            {data?.style_type == "7" &&
                <div className="d-flex flex-column align-items-center" style={{ height: `${(data?.height_small)}px`, width: "100%", color: "black" }}>
                    <div className="d-flex flex-row justify-content-center pt-2 mx-0" style={{ height: "100%", width: "100%", backgroundColor: "#F2F2F2" }}>
                        <div className="d-flex flex-column align-items-center" style={{ height: "100%", width: "90%", backgroundColor: "#F2F2F2" }}>
                            <div className="d-flex flex-column align-items-center" style={{ height: "60%", width: "100%", color: "black" }}>
                                <Card.Body style={{ width: "100%" }}>
                                    <Card.Title>{data?.title}{' '}{data?.style_type}</Card.Title>
                                    <Card.Text style={{ fontSize: "11px" }}>
                                        {data?.content1}
                                    </Card.Text>
                                    <Card.Text style={{ fontSize: "11px" }}>
                                        {data?.content2}
                                    </Card.Text>
                                </Card.Body>
                            </div>
                            <img src={`https://${process.env.REACT_APP_IMAGE_DISTRO}/img/${data?.picture1}`} className="card-img-top" style={{ height: "40%", width: "70%" }} alt="..."></img>
                        </div>
                    </div>
                </div>
            }
            {data?.style_type == "8" &&
                <div className="d-flex flex-row justify-content-center py-2 mx-0" style={{ height: `${data?.height_small}px`, width: "100%", backgroundColor: `${layout.color_background}` }}>
                    <Card style={{ width: "90%", border: "hidden", height: "100%", backgroundColor: `${layout.color_background}` }}>
                        <Card.Img variant="top" src={`https://${process.env.REACT_APP_IMAGE_DISTRO}/img/${data?.picture1}`} />
                        <Card.Body>
                            <Card.Text>
                                {data?.content1}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
            }
              {data?.style_type == "9" &&
                <div className="d-flex flex-row justify-content-center py-2 mx-0" style={{ height: `${data?.height_small}px`, width: "100%", backgroundColor: `${layout.color_background}` }}>
                    <Card className="bg-dark text-white" style={{ width: '100%' }}>
                        <Card.Img src={`https://${process.env.REACT_APP_IMAGE_DISTRO}/img/package-1.jpg`} alt="Card image" style={{ height: '450px' }} />
                        <Card.ImgOverlay>
                            <Card.Title>{data?.title}</Card.Title>
                            <Card.Text>
                                {data?.content1}
                            </Card.Text>
                        </Card.ImgOverlay>
                    </Card>
                </div>
            }
            {data?.style_type == "10" &&
                <div className="d-flex flex-row justify-content-center py-2 mx-0" style={{ height: `${data?.height_small}px`, width: "100%", backgroundColor: `${layout.color_background}` }}>
                    <Card className="bg-dark text-white" style={{ width: '90%' }}>
                        <Card.Img src={`https://${process.env.REACT_APP_IMAGE_DISTRO}/img/package-1.jpg`} alt="Card image" style={{ height: '450px' }} />
                        <Card.ImgOverlay>
                            <Card.Title>{data?.title}</Card.Title>
                            <Card.Text>
                                {data?.content1}
                            </Card.Text>
                        </Card.ImgOverlay>
                    </Card>
                </div>
            }
            {data?.style_type == "11" &&
                <div className="d-flex flex-row justify-content-center py-2 mx-0" style={{ height: `${data?.height_small}px`, width: "100%", backgroundColor: `${layout.color_background}` }}>
                    <CardGroup style={{width: '90%'}} >
                        <Card>
                            <Card.Img variant="top" src={`https://${process.env.REACT_APP_IMAGE_DISTRO}/img/${data?.picture1}`} />
                            <Card.Body style={{ backgroundColor: `${layout.color_background}`}}>
                                <Card.Text>
                                    {data?.content1}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Img variant="top" src={`https://${process.env.REACT_APP_IMAGE_DISTRO}/img/${data?.picture2}`} />
                            <Card.Body style={{ backgroundColor: `${layout.color_background}`}}>
                                <Card.Text>
                                    {data?.content2}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Img variant="top" src={`https://${process.env.REACT_APP_IMAGE_DISTRO}/img/${data?.picture3}`} />
                            <Card.Body style={{ backgroundColor: `${layout.color_background}`}}>
                                <Card.Text>
                                    {data?.content3}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </CardGroup>
                </div>
            }
        </>
    )
}

export default DisplayTextSmall