import React from 'react'

const LoadingPage = () => {
    return (
            <div className="text-center" style={{height: '50%'}}>
                <div className="spinner-border m-5 " style={{ width: "3rem", height: "3rem" }} role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
    )
}

export default LoadingPage