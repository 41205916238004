import React from 'react'
import { useNavigate } from "react-router-dom";
import { useStoreState } from 'easy-peasy';
import useAuth from "../../hooks/useAuth";


const AuthButtons = () => {
    const { auth, setAuth } = useAuth();
    const navigate = useNavigate();
    const layout = useStoreState((state) => state.layout);
  
    const signOut = () => {
      setAuth("");
      localStorage.setItem('refresh_token', "")
      navigate(`/`);
  }
  return (
    <div className="d-flex align-items-center">
    {!auth?.user &&
        <ul className="nav nav-pills nav-fill justify-content-end">
            <li className="nav-item">
                <a className="nav-link active text-light me-1" style={{ backgroundColor: `${layout?.color_main}`, width: '80px' }} href="/login">Login</a>
            </li>
            <li className="nav-item">
                <a className="nav-link active text-light" style={{ backgroundColor: `${layout?.color_main}`, width: '80px' }} href="/register">Sign Up</a>
            </li>
        </ul>
    }
    {auth?.user &&
        <ul className="nav nav-pills nav-fill justify-content-end">
            <li className="nav-item">
                <a className="nav-link active text-light" style={{ backgroundColor: `${layout?.color_main}` }} href="/profile">Profile</a>
            </li>
            <li className="nav-item">
                <a className="nav-link active text-light" style={{ backgroundColor: `${layout?.color_main}` }} onClick={signOut}>Sign Out</a>
            </li>
        </ul>
    }

</div>
  )
}

export default AuthButtons