import React from 'react'
import { useStoreState } from 'easy-peasy';

const FooterLinks = () => {
    const footers = useStoreState((state) => state.footers);
    return (
        <>
            <div className="d-flex flex-row justify-content-between">
                {footers?.map(link => (

                    <div className="d-flex flex-column align-items-start" key={link.id}>
                        <h5 className="mt-5" style={{ color: 'white' }} key={link.id}>{link.title}</h5>
                        {link.links?.map(link => (
                            <a className="text-light" key={link.id} href={link.link} style={{ color: 'white', textDecoration: 'none', fontSize: '16px', fontWeight: 'lighter' }}>{link.title} </a>
                        ))}
                    </div>
                ))}
            </div>
        </>
    )
}

export default FooterLinks