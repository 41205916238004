import { useEffect, useState } from 'react';
import { Outlet } from "react-router-dom"
import { useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { useStoreActions, useStoreState } from 'easy-peasy';
import Title from './Title';
import Missing from '../utility/Missing';
import LoadingPage from '../utility/LoadingPage';
import ListItems from './ListItems';
import ListTexts from './ListTexts';
import { InlineWidget } from 'react-calendly';
import Card from 'react-bootstrap/Card';
import Carousel from 'react-bootstrap/Carousel';

const Page = () => {

    const navigate = useNavigate();
    const { page_name, category_name } = useParams();
    const layout = useStoreState((state) => state.layout);
    const width = useStoreState((state) => state.width);
    const page = useStoreState((state) => state.page);
    const home = useStoreState((state) => state.home);
    const set_page_check = useStoreActions((actions) => actions.set_page_check);
    const set_home_check = useStoreActions((actions) => actions.set_home_check);
    const [userEmail, setUserEmail] = useState('');
    const [index, setIndex] = useState(0);
    console.log(page)
    console.log(home)
    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };
    const handleInputChange = (event) => {
        setUserEmail(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // Handle form submission logic here
        // You can access the entered email using the userEmail state
        console.log('User Email:', userEmail);
    };
    useEffect(() => {
        set_page_check(true)
        if (!page_name) {
            set_home_check(true)
        }
        return () => {
            set_page_check(false)
            set_home_check(false)
        };
    }, [])

    const handleShopNow = () => {
        //navigate(`${page.home[0].links[0].url}`)
        navigate(`${home.button_link}`)
    }
    return (
        <>
            {layout == 'loading' && <LoadingPage />}
            <>
                {page != 'loading' && layout != 'loading' && page?.published == true &&
                    <>
                        {!page_name &&
                            <>
                                <div className="container-fluid p-0" style={{ height: "100%", width: "100%", color: "black" }}>
                                    <div className="d-flex flex-column justify-content-center" style={{ height: "100%", width: "100%" }}>
                                        <div className="d-flex flex-row justify-content-center pt-0" style={{ height: "100%" }} >
                                            <div className="container-fluid p-0" style={{ height: "100%", width: '100%' }}>
                                                {/* <ListTexts texts={page?.texts} /> */}
                                                {width >= 811 &&
                                                    <div style={{ height: "100%" }}>
                                                        <main className="Home" style={{ height: "100%" }}>
                                                            <div className="d-flex flex-column align-items-center" style={{ height: "100%" }}>
                                                                <div className="d-flex flex-row justify-content-center py-0 mx-0" style={{ height: `100%`, width: "100%", backgroundColor: '#f0f0f0' }}>
                                                                    <Card style={{ width: "100%", border: "hidden", backgroundColor: '#f0f0f0' }}>
                                                                        <Card.Img src={`https://${process.env.REACT_APP_IMAGE_DISTRO}/img/${home.back_ground_image}`} alt="Card image" style={{ height: '100%' }} />
                                                                        <Card.ImgOverlay className='p-0'>
                                                                            <div className="d-flex flex-row justify-content-evenly align-items-center" style={{ height: "100%", width: "100%" }}>
                                                                                <div className="d-flex flex-column align-items-center" style={{ height: "60%", width: "50%", color: "black" }}>
                                                                                    <Card.Body style={{ width: "90%", color: `black`, fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '64px', textShadow: '3px 3px 3px #ababab' }}>
                                                                                        <h1>{home.content1}</h1>
                                                                                        <button className='m-2' style={{ width: '150px', color: 'white', fontSize: '20px', backgroundColor: `${layout.color_main}`, height: "50px", border: ` solid ${layout.color_main}`, borderRadius: '20px' }} size='sm' onClick={() => handleShopNow()} >{home.button_text}</button>
                                                                                    </Card.Body>
                                                                                </div>
                                                                                <img src={`https://${process.env.REACT_APP_IMAGE_DISTRO}/img/${home.main_image}`} className="card-img-top" style={{ height: "100%", width: `${home.main_image_width}px` }} alt="..."></img>
                                                                            </div>
                                                                        </Card.ImgOverlay>
                                                                    </Card>
                                                                </div>
                                                            </div>
                                                        </main>
                                                    </div>
                                                }
                                                {width < 811 &&
                                                    <div style={{ height: "100%" }}>
                                                        <main className="Home" style={{ height: "100%" }}>
                                                            <div className="d-flex flex-column align-items-center" style={{ height: "100%" }}>
                                                                <div className="d-flex flex-row justify-content-center py-0 mx-0" style={{ height: `100%`, width: "100%" }}>
                                                                    <div className="d-flex flex-row justify-content-evenly align-items-center" style={{ height: "100%", width: "100%" }}>
                                                                        <div className="d-flex flex-column align-items-center" style={{ height: "60%", width: "90%", color: "black", fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '64px', textShadow: '3px 3px 3px #ababab' }}>
                                                                            <h1>{home.content1}</h1>
                                                                            <button className='m-2' style={{ width: '150px', color: 'white', fontSize: '20px', backgroundColor: `${layout.color_main}`, height: "50px", border: ` solid ${layout.color_main}`, borderRadius: '20px' }} size='sm' onClick={() => handleShopNow()} >Shop Now</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </main>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                        {page_name &&
                            <>
                                {page?.page_type != "contact" &&
                                    <>
                                        <div className="container-fluid p-0" style={{ height: "100%", width: "100%", color: "black" }}>
                                            <Title type={page?.page_type} title={page?.title} />
                                            {category_name && <Outlet />}
                                            {!category_name &&
                                                <>
                                                    <div className="container-fluid p-0" style={{ height: "100%", width: "100%", color: "black" }}>
                                                        <div className="d-flex flex-row justify-content-center pt-3" style={{ height: "100%" }} >
                                                            {page?.page_type == "text" &&
                                                                <div className="container-fluid p-0" style={{ height: "100%" }}>
                                                                    <div className="d-flex flex-row justify-content-center">
                                                                        <h3 style={{ color: "black" }}>{page?.title}</h3>
                                                                    </div>
                                                                    <ListTexts texts={page?.texts} />
                                                                </div>
                                                            }
                                                            {page?.page_type == "product" &&
                                                                <div className="container-fluid p-0" style={{ height: "100%", width: "100%" }}>
                                                                    <div className="d-flex flex-row justify-content-center">
                                                                        <h3 style={{ color: "black" }}>{page?.title} </h3>
                                                                    </div>
                                                                    <ListItems data={page?.products} />
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </>
                                }
                                {page?.page_type == "contact" &&
                                    <>
                                        <div className="container-fluid pt-2" style={{ height: "1200px", width: "85%", color: "black" }}>
                                            <div className="d-flex flex-column justify-content-center" style={{ height: '100%' }}>
                                                <Title type={page?.page_type} title={page?.title} />
                                                <div className="d-flex flex-row justify-content-center pt-3" style={{ height: "100%" }} >
                                                    <div className="d-flex flex-column pt-2 mx-0" style={{ height: "100%", width: "100%", alignItems: "center" }}>
                                                        {width >= 811 &&
                                                            <Carousel fade interval={null} style={{ height: "900px", width: "100%", alignItems: "center" }}>
                                                                <Carousel.Item style={{ height: "900px", width: "100%" }}>
                                                                    <div className="d-flex flex-row justify-content-center" style={{ height: "100%", width: "100%" }} >
                                                                        <div className="d-flex flex-row justify-content-center py-0 mx-0" style={{ height: `100%`, width: "100%", backgroundColor: '#f0f0f0' }}>
                                                                            <Card style={{ width: "100%", border: "hidden", backgroundColor: '#f0f0f0' }}>
                                                                                <Card.Img src={`https://${process.env.REACT_APP_IMAGE_DISTRO}/img/${page?.contacts[0]?.back_ground_image}`} alt="Card image" style={{ height: '100%' }} />
                                                                                <Card.ImgOverlay className='p-0'>
                                                                                    <div className="d-flex flex-row justify-content-evenly align-items-center" style={{ height: "100%", width: "100%" }}>
                                                                                        <img src={`https://${process.env.REACT_APP_IMAGE_DISTRO}/img/${page?.contacts[0]?.main_image}`} className="card-img-top" style={{ height: "100%", width: "600px" }} alt="..."></img>
                                                                                        <div className="d-flex flex-column align-items-center" style={{ height: "60%", width: "50%", color: "black" }}>

                                                                                            <h1 style={{ color: "black", fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '64px', textShadow: '3px 3px 3px #ababab' }}>Get in Touch</h1>

                                                                                            <div className="d-flex-row justify-content-center" style={{ width: '100%' }}>
                                                                                                <div className="d-flex flex-column align-items-center">
                                                                                                    <h5 style={{ color: "black" }}>{page?.contacts[0]?.email}</h5>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="d-flex-row justify-content-center" style={{ width: '100%' }}>
                                                                                                <div className="d-flex flex-column align-items-center">
                                                                                                    <h5 style={{ color: "black" }}>{page?.contacts[0]?.phone_number}</h5>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="d-flex-row justify-content-center" style={{ width: '100%' }}>
                                                                                                <div className="d-flex flex-row justify-content-center align-items-center">
                                                                                                    <a href={`https://${page?.contacts[0]?.twitter}`}><i style={{ color: "black" }} className="fa-brands fa-twitter fa-2x px-2"></i></a>
                                                                                                    <a href={`https://${page?.contacts[0]?.facebook}`}><i style={{ color: "black" }} className="fa-brands fa-facebook fa-2x px-2"></i></a>
                                                                                                    <a href={`https://${page?.contacts[0]?.linkedin}`}><i style={{ color: "black" }} className="fa-brands fa-linkedin fa-2x px-2"></i></a>
                                                                                                    <a href={`https://${page?.contacts[0]?.youtube}`}><i style={{ color: "black" }} className="fa-brands fa-youtube fa-2x px-2"></i></a>
                                                                                                    <a href={`https://${page?.contacts[0]?.instagram}`}><i style={{ color: "black" }} className="fa-brands fa-instagram fa-2x px-2"></i></a>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </Card.ImgOverlay>
                                                                            </Card>
                                                                        </div>

                                                                    </div>
                                                                </Carousel.Item>
                                                                <Carousel.Item style={{ height: "900px", width: "100%" }}>
                                                                    <div className="d-flex flex-row justify-content-center py-0 mx-0" style={{ height: `100%`, width: "100%", backgroundColor: '#f0f0f0' }}>
                                                                        <Card style={{ width: "100%", border: "hidden", backgroundColor: '#f0f0f0' }}>
                                                                            <Card.Img src={`https://${process.env.REACT_APP_IMAGE_DISTRO}/img/homepage-bg5.jpg`} alt="Card image" style={{ height: '100%' }} />
                                                                            <Card.ImgOverlay className='p-0'>
                                                                                <div className="d-flex flex-row justify-content-left align-items-center" style={{ height: "100%", width: "100%" }}>
                                                                                    <div className="d-flex flex-column align-items-end" style={{ height: "100%", width: "70%", color: "black" }}>
                                                                                        <Card.Body style={{ width: "90%", color: `black`, fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '64px', textShadow: '3px 3px 3px #ababab' }}>
                                                                                            <div className="d-flex flex-column pt-2 mx-0" style={{ height: "100%", width: "100%", alignItems: "center" }}>
                                                                                                <InlineWidget
                                                                                                    url={page?.contacts[0]?.calendly}
                                                                                                    styles={{
                                                                                                        height: '900px',
                                                                                                        width: '400px',
                                                                                                        backgroundColor: 'transparent'
                                                                                                    }}
                                                                                                />
                                                                                            </div>                                                                                    </Card.Body>
                                                                                    </div>
                                                                                </div>
                                                                            </Card.ImgOverlay>
                                                                        </Card>
                                                                    </div>
                                                                </Carousel.Item>
                                                            </Carousel>
                                                        }
                                                        {width < 811 &&
                                                            <>
                                                                <h1 style={{ color: "black", fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '36px', textShadow: '3px 3px 3px #ababab' }}>Get in Touch</h1>
                                                                <div className="d-flex-row justify-content-center" style={{ width: '100%' }}>
                                                                    <div className="d-flex flex-column align-items-center">
                                                                        <h5 style={{ color: "black" }}>{page?.contacts[0]?.email}</h5>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex-row justify-content-center" style={{ width: '100%' }}>
                                                                    <div className="d-flex flex-column align-items-center">
                                                                        <h5 style={{ color: "black" }}>{page?.contacts[0]?.phone_number}</h5>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex-row justify-content-center" style={{ width: '100%' }}>
                                                                    <div className="d-flex flex-row justify-content-center align-items-center">
                                                                        <a href={`https://${page?.contacts[0]?.twitter}`}><i style={{ color: "black" }} className="fa-brands fa-twitter fa-2x px-2"></i></a>
                                                                        <a href={`https://${page?.contacts[0]?.facebook}`}><i style={{ color: "black" }} className="fa-brands fa-facebook fa-2x px-2"></i></a>
                                                                        <a href={`https://${page?.contacts[0]?.linkedin}`}><i style={{ color: "black" }} className="fa-brands fa-linkedin fa-2x px-2"></i></a>
                                                                        <a href={`https://${page?.contacts[0]?.youtube}`}><i style={{ color: "black" }} className="fa-brands fa-youtube fa-2x px-2"></i></a>
                                                                        <a href={`https://${page?.contacts[0]?.instagram}`}><i style={{ color: "black" }} className="fa-brands fa-instagram fa-2x px-2"></i></a>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex-row justify-content-center pt-4">
                                                                    <InlineWidget
                                                                        url={page?.contacts[0]?.calendly}
                                                                        styles={{
                                                                            height: '500px',
                                                                            width: '200px',
                                                                            backgroundColor: 'transparent'
                                                                        }}
                                                                    />
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                            </>
                        }
                    </>
                }
                {page?.published == false &&
                    <>
                        <Missing />
                    </>
                }
            </>
        </>
    )
}

export default Page
