import { useNavigate } from "react-router-dom"

const Unauthorized = () => {
    const navigate = useNavigate();

    const guest = () => navigate('/checkout/guest/details');
    const login = () => navigate('/login');

    return (
        <section>
            <h4>Login or Continue as guest?</h4>
            <br />
            <div className="flexGrow">
                <button onClick={guest}>Guest</button>
                <button onClick={login}>Login</button>
            </div>
        </section>
    )
}

export default Unauthorized
