import React, { useState, useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import api from '../../api/axios';
import useLocalStorage from "../../hooks/useLocalStorage";

const OrderConfirmation = () => {
    const [message, setMessage] = useState('test');
    const { auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const payment_intent = new URLSearchParams(window.location.search).get(
        'payment_intent'
    );
    const payment_info = useStoreState((state) => state.payment_info);
    const order_info = useStoreState((state) => state.order_info);
    const cart_items = useStoreState((state) => state.cart_items);
    const set_order_info = useStoreActions((actions) => actions.set_order_info);
    const set_payment_info = useStoreActions((actions) => actions.set_payment_info);
    const set_order_check = useStoreActions((actions) => actions.set_order_check);
    const set_cart_banner_check = useStoreActions((actions) => actions.set_cart_banner_check);
    const set_details_banner_check = useStoreActions((actions) => actions.set_details_banner_check);
    const set_payment_banner_check = useStoreActions((actions) => actions.set_payment_banner_check);
    const set_order_banner_check = useStoreActions((actions) => actions.set_order_banner_check);
    const set_cart_items = useStoreActions((actions) => actions.set_cart_items);

    useEffect(() => {
        set_order_check(true)
        set_order_banner_check(true)
        set_payment_banner_check(true)
        set_details_banner_check(true)
        set_cart_banner_check(true)
        return () => {
            set_order_check(false)
            set_order_banner_check(false)
            set_payment_banner_check(false)
            set_details_banner_check(false)
            set_cart_banner_check(false)
        };
    }, [])
    useEffect(() => {
        const payload = {
            uuid: `${process.env.REACT_APP_UUID}`,
            cart_items: cart_items,
            payment_intent: '' + payment_intent
        }
        if (payment_intent && payload) {
            const getConfirmationGuest = async (payload) => {
                try {
                    const response = await api.post(`/tier1/payment/guest/retrieve_confirmation/${payload.payment_intent}`, payload);
                    set_payment_info(response.data?.payment_info?.body)
                    set_order_info(response.data?.order_info)
                } catch (err) {
                    console.log(`Error: ${err.message}`);
                }
                set_cart_items([])
                localStorage.setItem('cart_items', JSON.stringify([]))
            }
            const getConfirmationUser = async (payload) => {
                try {
                    const response = await axiosPrivate.post(`/tier1/payment/user/retrieve_confirmation/${payload.payment_intent}`, payload);
                    set_payment_info(response.data?.payment_info?.body)
                    set_order_info(response.data?.order_info)
                } catch (err) {
                    console.log(`Error: ${err.message}`);
                }
                clear_cart(auth?.id)
            }
            if (!auth?.id) {
                getConfirmationGuest(payload)
            }
            else {
                getConfirmationUser(payload)
            }
        }
    }, [payment_intent])

    // Retrieve the PaymentIntent
    useEffect(() => {
        console.log(order_info)
        // Inspect the PaymentIntent `status` to indicate the status of the payment
        // to your customer.
        //
        // Some payment methods will [immediately succeed or fail][0] upon
        // confirmation, while others will first enter a `processing` state.
        //
        // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
        switch (payment_info?.status) {
            case 'succeeded':
                setMessage('Success! Payment received.');
                break;

            case 'processing':
                setMessage("Payment processing. We'll update you when payment is received.");
                break;

            case 'requires_payment_method':
                // Redirect your user back to your payment page to attempt collecting
                // payment again
                setMessage('Payment failed. Please try another payment method.');
                break;

            default:
                setMessage('');
                break;
        }
    }, [payment_info, order_info])


    return (
        <><div className="d-flex flex-column align-items-center">
            <div className="d-flex flex-row ps-1 pb-3">
                <h3 style={{ color: "black" }}>Order Confirmation</h3>
            </div>
            <h1 style={{ color: 'black' }}>{message}</h1>
        </div>
        </>
    );
};

export default OrderConfirmation;