import { createStore, action, thunk, computed } from "easy-peasy";
import api from './api/axios';
import axiosPrivate from './api/axios';

export default createStore({
    //Admin States

    layout_check: false,
    set_layout_check: action((state, payload) => {
        state.layout_check = payload;
    }),
    home_check: false,
    set_home_check: action((state, payload) => {
        state.home_check = payload;
    }),
    page_check: false,
    set_page_check: action((state, payload) => {
        state.page_check = payload;
    }),
    category_check: false,
    set_category_check: action((state, payload) => {
        state.category_check = payload;
    }),
    subcategory_check: false,
    set_subcategory_check: action((state, payload) => {
        state.subcategory_check = payload;
    }),
    subcategory2_check: false,
    set_subcategory2_check: action((state, payload) => {
        state.subcategory2_check = payload;
    }),
    item_check: false,
    set_item_check: action((state, payload) => {
        state.item_check = payload;
    }),
    total: 20,
    set_total: action((state, payload) => {
        state.total = payload;
    }),
    dirs: 5,
    set_dirs: action((state, payload) => {
        state.dirs = payload;
    }),
    dir: 1,
    set_dir: action((state, payload) => {
        state.dir = payload;
    }),
    offset: '',
    set_offset: action((state, payload) => {
        state.offset = payload;
    }),
    navbar: [{}],
    set_navbar: action((state, payload) => {
        state.navbar = payload;
    }),
    dropdown1: false,
    set_dropdown1: action((state, payload) => {
        state.dropdown1 = payload;
    }),
    dropdown2: false,
    set_dropdown2: action((state, payload) => {
        state.dropdown2 = payload;
    }),
    width: '',
    set_width: action((state, payload) => {
        state.width = payload;
    }),
    height: 2200,
    set_height: action((state, payload) => {
        state.height = payload;
    }),
    layout_height: 3800,
    set_layout_height: action((state, payload) => {
        state.layout_height = payload;
    }),
    layout: 'loading',
    set_layout: action((state, payload) => {
        state.layout = payload;
    }),
    footers: [],
    set_footers: action((state, payload) => {
        state.footers = payload;
    }),
    footer: '',
    set_footer: action((state, payload) => {
        state.footer = payload;
    }),
    links: [],
    set_links: action((state, payload) => {
        state.links = payload;
    }),
    link: '',
    set_link: action((state, payload) => {
        state.link = payload;
    }),
    page: 'loading',
    set_page: action((state, payload) => {
        state.page = payload;
    }),
    home: 'loading',
    set_home: action((state, payload) => {
        state.home = payload;
    }),
    category: 'loading',
    set_category: action((state, payload) => {
        state.category = payload;
    }),
    subcategory: 'loading',
    set_subcategory: action((state, payload) => {
        state.subcategory = payload;
    }),
    subcategory2: 'loading',
    set_subcategory2: action((state, payload) => {
        state.subcategory2 = payload;
    }),
    items: [],
    set_items: action((state, payload) => {
        state.items = payload;
    }),
    item: '',
    set_item: action((state, payload) => {
        state.item = payload;
    }),
    item_count: computed((state) => state.items?.length),
    get_item_by_id: computed((state) => {
        return (id) => state.items?.find(item => (item.id).toString() === id);
    }),
    texts: [],
    set_texts: action((state, payload) => {
        state.texts = payload;
    }),
    text: '',
    set_text: action((state, payload) => {
        state.text = payload;
    }),
    text_count: computed((state) => state.texts?.length),
    get_text_by_id: computed((state) => {
        return (id) => state.texts?.find(text => (text.id).toString() === id);
    }),
    user: '',
    set_user: action((state, payload) => {
        state.user = payload;
    }),
    username: '',
    set_username: action((state, payload) => {
        state.username = payload;
    }),
    user_email: '',
    set_user_email: action((state, payload) => {
        state.user_email = payload;
    }),
    user_password: '',
    set_user_password: action((state, payload) => {
        state.user_password = payload;
    }),
    edit_username: '',
    set_edit_username: action((state, payload) => {
        state.edit_username = payload;
    }),
    edit_user_email: '',
    set_edit_user_email: action((state, payload) => {
        state.edit_user_email = payload;
    }),
    edit_user_password: '',
    set_edit_user_password: action((state, payload) => {
        state.edit_user_password = payload;
    }),
    logged_user: '',
    set_logged_user: action((state, payload) => {
        state.logged_user = payload;
    }),
    get_customer_layout: thunk(async (actions) => {
        try {
            const response = await api.get(`/tier1/layout/${process.env.REACT_APP_UUID}`);
            console.log(response?.data)
            actions.set_layout(response?.data.layout);
            actions.set_footers([...response.data?.footers,]);
            actions.set_navbar([...response.data?.navbar,]);
        } catch (err) {
            console.log(`Error: ${err.message}`);
        }
    }),
    get_customer_home: thunk(async (actions) => {
        try {
            const response = await api.get(`/tier1/layout/${process.env.REACT_APP_UUID}/home`);
            actions.set_page(response?.data.page);
            actions.set_home(response?.data.home);
            actions.set_texts(response?.data.page.texts);
        } catch (err) {
            console.log(`Error: ${err.message}`);
        }
    }),
    get_customer_page: thunk(async (actions, payload) => {
        const { page_name } = payload;
        try {
            const response = await api.get(`/tier1/layout/${process.env.REACT_APP_UUID}/page/${page_name}`);
            actions.set_page(response?.data.page);
            actions.set_texts(response?.data.page.texts);
        } catch (err) {
            console.log(`Error: ${err.message}`);
        }
    }),
    get_customer_category: thunk(async (actions, payload) => {
        const { page_name, category_name } = payload;
        try {
            const response = await api.get(`/tier1/layout/${process.env.REACT_APP_UUID}/page/${page_name}/category/${category_name}`);
            actions.set_page(response?.data.page);
            actions.set_category(response.data.category);
            actions.set_texts(response.data.category.texts);
        } catch (err) {
            console.log(`Error: ${err.message}`);
        }
    }),
    get_customer_subcategory: thunk(async (actions, payload) => {
        const { page_name, category_name, subcategory_name } = payload;
        try {
            const response = await api.get(`/tier1/layout/${process.env.REACT_APP_UUID}/page/${page_name}/category/${category_name}/subcategory/${subcategory_name}`);
            actions.set_page(response?.data.page);
            actions.set_category(response.data.category);
            actions.set_subcategory(response.data.subcategory);
            actions.set_texts(response.data.subcategory.texts);
        } catch (err) {
            console.log(`Error: ${err.message}`);
        }
    }),
    get_customer_subcategory2: thunk(async (actions, payload) => {
        const { page_name, category_name, subcategory_name, subcategory2_name } = payload;
        try {
            const response = await api.get(`/tier1/layout/${process.env.REACT_APP_UUID}/page/${page_name}/category/${category_name}/subcategory/${subcategory_name}/subcategory2/${subcategory2_name}`);
            actions.set_page(response?.data.page);
            actions.set_category(response.data.category);
            actions.set_subcategory(response.data.subcategory);
            actions.set_subcategory2(response.data.subcategory2);
            actions.set_texts(response.data.subcategory2.texts);
        } catch (err) {
            console.log(`Error: ${err.message}`);
        }
    }),
    get_customer_item: thunk(async (actions, ref) => {
        try {
            const response = await api.get(`/tier1/layout/product/${ref}`);
            actions.set_page(response?.data.page);
            actions.set_category(response.data.category);
            actions.set_subcategory(response.data.subcategory);
            actions.set_subcategory2(response.data.subcategory2);
            actions.set_item(response.data.product);
        } catch (err) {
            console.log(`Error: ${err.message}`);
        }
    }),
    delete_user: thunk(async (payload) => {
        try {
            await axiosPrivate.delete(`/users/id/${payload?.id}`);
        } catch (err) {
            console.log(`Error: ${err.message}`);
        }
    }),
    edit_user: thunk(async (actions, payload) => {
        const { id, user } = payload;
        try {
            const response = await axiosPrivate.put(`/users/id/${id}`, user);
            actions.set_edit_username(response.data?.username);
            actions.set_edit_user_email(response.data?.email);
            actions.set_edit_user_password(response.data?.password);
        } catch (err) {
            console.log(`Error: ${err.message}`);
        }
    }),
    clientSecret: null,
    setClientSecret: action((state, payload) => {
        state.clientSecret = payload;
    }),
    cart_item_ids: ['prod_N8KoTVA6hl8IXh'],
    set_cart_item_ids: action((state, payload) => {
        state.cart_item_ids = payload;
    }),
    cart_items: [],
    set_cart_items: action((state, payload) => {
        state.cart_items = payload;
    }),
    payment_complete: false,
    set_payment_complete: action((state, payload) => {
        state.payment_complete = payload;
    }),
    details: '',
    set_details: action((state, payload) => {
        state.details = payload;
    }),
    quantity: 1,
    set_quantity: action((state, payload) => {
        state.quantity = payload;
    }),
    order_info: '',
    set_order_info: action((state, payload) => {
        state.order_info = payload;
    }),
    payment_info: '',
    set_payment_info: action((state, payload) => {
        state.payment_info = payload;
    }),
    shipping_info: null,
    set_shipping_info: action((state, payload) => {
        state.shipping_info = payload;
    }),
    shipping_rate_list: '',
    set_shipping_rate_list: action((state, payload) => {
        state.shipping_rate_list = payload;
    }),
    shipping_rate: null,
    set_shipping_rate: action((state, payload) => {
        state.shipping_rate = payload;
    }),
    total: '',
    set_total: action((state, payload) => {
        state.total = payload;
    }),
    shipping: 0,
    set_shipping: action((state, payload) => {
        state.shipping = payload;
    }),
    tax: 0,
    set_tax: action((state, payload) => {
        state.tax = payload;
    }),
    grand_total: '',
    set_grand_total: action((state, payload) => {
        state.grand_total = payload;
    }),
    order: '',
    set_order: action((state, payload) => {
        state.order = payload;
    }),
    cart_banner_check: false,
    set_cart_banner_check: action((state, payload) => {
        state.cart_banner_check = payload;
    }),
    details_banner_check: false,
    set_details_banner_check: action((state, payload) => {
        state.details_banner_check = payload;
    }),
    payment_banner_check: false,
    set_payment_banner_check: action((state, payload) => {
        state.payment_banner_check = payload;
    }),
    order_banner_check: false,
    set_order_banner_check: action((state, payload) => {
        state.order_banner_check = payload;
    }),
    cart_check: false,
    set_cart_check: action((state, payload) => {
        state.cart_check = payload;
    }),
    details_check: false,
    set_details_check: action((state, payload) => {
        state.details_check = payload;
    }),
    payment_check: false,
    set_payment_check: action((state, payload) => {
        state.payment_check = payload;
    }),
    order_check: false,
    set_order_check: action((state, payload) => {
        state.order_check = payload;
    }),
    banner_check: '',
    set_banner_check: action((state, payload) => {
        state.banner_check = payload;
    }),
    cart_count: computed((state) => state.cart_items?.length),
    get_cart_total: computed((state) => {
        const array = state.cart_items
        let total = 0
        for (let index = 0; index < array?.length; index++) {
            total += (array[index]?.unit_amount * array[index]?.quantity);
        }
        return total
    }),
    get_cart_by_id: computed((state) => {
        return (id) => state.cart_items?.find(item => (item.id).toString() === id);
    }),
    get_cart: thunk(async (actions, id) => {
        try {
            const response = await api.get(`/tier1/cart/user/${id}/get`);
            actions.set_cart_items(response.data);
            console.log(response.data);
        } catch (err) {
            console.log(`Error: ${err.message}`);
        }
    }),
    clear_cart: thunk(async (actions, id) => {
        try {
            const response = await api.get(`/tier1/cart/user/${id}/clear`);
            actions.set_cart_items([]);
        } catch (err) {
            console.log(`Error: ${err.message}`);
        }
    }),
    add_to_cart: thunk(async (actions, payload, helpers) => {
        const { id, new_item } = payload;
        const { cart_items } = helpers.getState();
        try {
            const response = await api.post(`/tier1/cart/user/${id}/add`, new_item);
            console.log("Testing add to cart logged user")
            console.log(response.data)
            console.log([...cart_items, response.data])
            actions.set_cart_items(response.data);
        } catch (err) {
            console.log(`Error: ${err.message}`);
        }
    }),
    update_cart: thunk(async (actions, payload, helpers) => {
        const { id, items } = payload;
        const { cart_items } = helpers.getState();
        try {
            const response = await api.put(`/tier1/cart/user/${id}/update`, items);
            console.log("Testing update cart")
            console.log(response.data)
            actions.set_cart_items(response.data);
        } catch (err) {
            console.log(`Error: ${err.message}`);
        }
    }),
    remove_from_cart: thunk(async (actions, payload, helpers) => {
        const { id, ref } = payload;
        const { cart_items } = helpers.getState();
        try {
            const response = await api.put(`/tier1/cart/user/${id}/remove/${ref}`, cart_items.filter(item => item.id !== ref));
            actions.set_cart_items(cart_items.filter(item => item.id !== ref));
            console.log(response)
        } catch (err) {
            console.log(`Error: ${err.message}`);
        }
    }),
    change_quantity_from_cart: thunk(async (actions, payload, helpers) => {
        const { id, ref, quantity } = payload;
        const { cart_items } = helpers.getState();
        try {
            const response = await api.put(`/tier1/cart/user/${id}/change_quantity/${ref}`, payload);
            actions.set_cart_items(cart_items);
            window.location.reload(false);
        } catch (err) {
            console.log(`Error: ${err.message}`);
        }
    }),
    change_quantity_from_cart_guest: thunk(async (actions, payload, helpers) => {
        const { ref, quantity } = payload;
        const { cart_items } = helpers.getState();
        let found = false
        for (let index = 0; index < cart_items.length && found == false; index++) {
            if (cart_items[index].id == ref) {
                cart_items[index].quantity = quantity
            }
            console.log(cart_items[index].quantity)
        }
        actions.set_cart_items(cart_items);
        localStorage.setItem('cart_items', JSON.stringify(cart_items))
        window.location.reload(false);
    }),
    add_to_cart_guest: thunk(async (actions, payload, helpers) => {
        const { new_item } = payload;
        const { cart_items } = helpers.getState();
        try {
            const response = await api.post(`/tier1/cart/guest/add`, new_item);
            const newItem = response.data.product
            newItem.quantity = response.data.quantity
            let found = false
            let index = 0
            if (cart_items?.length > index) {
                while (index < cart_items?.length && found == false) {
                    if (cart_items[index]?.id == newItem.id) {
                        cart_items[index].quantity = cart_items[index].quantity + newItem.quantity
                        actions.set_cart_items([...cart_items]);
                        localStorage.setItem('cart_items', JSON.stringify([...cart_items]))
                        found = true
                    }
                    else {
                        console.log("no match")
                    }
                    index++
                }
                if (found == false) {
                    actions.set_cart_items([...cart_items, newItem]);
                    localStorage.setItem('cart_items', JSON.stringify([...cart_items, newItem]))
                }
            }
            else {
                actions.set_cart_items([...cart_items, newItem]);
                localStorage.setItem('cart_items', JSON.stringify([...cart_items, newItem]))
            }
        } catch (err) {
            console.log(`Error: ${err.message}`);
        }
    }),
    remove_from_cart_guest: thunk(async (actions, payload, helpers) => {
        const { id, ref } = payload;
        const { cart_items } = helpers.getState();
        try {
            const response = await api.delete(`/tier1/cart/guest/remove/${ref}`);
            console.log(response)
            localStorage.setItem('cart_items', JSON.stringify(cart_items.filter(item => item.id !== ref)))
            actions.set_cart_items(cart_items.filter(item => item.id !== ref));
            console.log(cart_items.filter(item => item.id !== ref))
        } catch (err) {
            console.log(`Error: ${err.message}`);
        }
    }),
    create_payment_intent: thunk(async (actions, cart_items) => {
        try {
            const response = await api.post('/tier1/payment/guest/create_intent', cart_items);
            console.log(response.data);
            actions.setClientSecret(response.data?.body?.client_secret);
        } catch (err) {
            console.log(`Error: ${err.message}`);
        }
    }),
    retrieve_payment_confirmation: thunk(async (actions, value) => {
        try {
            const response = await api.get(`/tier1/payment/guest/retrieve_confirmation/${value}`);
            console.log(response.data);
            actions.set_payment_info(response.data?.payment_info?.body);
            actions.set_order_info(response.data?.order_info);
        } catch (err) {
            console.log(`Error: ${err.message}`);
        }
    }),
    process_shipping_info: thunk(async (actions, payload) => {
        try {
            const response = await api.post(`/tier1/payment/guest/process_shipping`, payload);
            console.log(response.data);
            console.log(response.data?.payment_info?.body?.client_secret);
            actions.setClientSecret(response.data?.payment_info?.body?.client_secret);
            actions.set_shipping(response.data?.shipping2?.shipment_cost?.amount);
            actions.set_tax(response.data?.tax_info?.tax_breakdown[0]?.amount);
        } catch (err) {
            console.log(`Error: ${err.message}`);
        }
    }),
    post_payments: thunk(async (actions, payload) => {

        try {
            const response = await api.post('/payments/create-checkout-session', payload);
            console.log(response.data);
            actions.set_payment_info(response.data);
        } catch (err) {
            console.log(`Error: ${err.message}`);
        }
    }),
});