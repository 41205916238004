import { Routes, Route } from 'react-router-dom';
import Login from './components/auth/Login';
import Register from './components/auth/Register';
import Missing from './components/utility/Missing';
import AuthCheck from './components/utility/AuthCheck';
import Unauthorized from './components/utility/Unauthorized';
import Success from './components/utility/Success';
import RequireAuth from './components/auth/RequireAuth';
import PersistLogin from './components/auth/PersistLogin';
import CheckCart from './components/auth/CheckCart';
import Page from './components/pages/Page';
import GetPage from './components/global/GetPage';
import Layout from './components/layout/Layout';
import PageSizing from './components/global/PageSizing';
import GetLayout from './components/global/GetLayout';
import Item from './components/pages/Item';
import Category from './components/pages/Category';
import Width from './components/global/Width';
import Checkout from './components/payments/Checkout';
import Cart from './components/payments/Cart';
import ShippingInfo from './components/payments/ShippingInfo';
import Payment from './components/payments/Payment';
import OrderConfirmation from './components/payments/OrderConfirmation';
import Main from './components/global/Main';
import SubCategory from './components/pages/SubCategory';
import SubCategory2 from './components/pages/SubCategory2';
import RequireAuthCheckOut from './components/auth/RequireAuthCheckOut';






const ROLES = {
  'User': 2001,
  'Developer': 2005,
  'Admin': 5150,
  'Operator': 1774
}

function App() {



  return (

    <Routes>
      {/* access routes */}

      <Route element={<GetLayout />}>
        <Route element={<Width />}>


          <Route path="/login" element={<Login allowedRoles={[ROLES.Admin, ROLES.Developer]} />} />
          <Route path="/register" element={<Register />} />
          <Route path="/unauthorized" element={<Unauthorized />} />

          <Route element={<PersistLogin />}>


            <Route element={<CheckCart />}>
              <Route element={<GetPage />}>
                <Route element={<PageSizing />}>
                  <Route element={<Main />}>

                    <Route path="/" element={<Layout />}>
                      <Route path="/authcheck" element={<AuthCheck />} />
                      <Route path="/checkout" element={<Checkout />}>
                        <Route path="/checkout/cart" element={<Cart />} />
                        <Route path="/checkout/guest/details" element={<ShippingInfo />} />
                        <Route path="/checkout/guest/payment" element={<Payment />} />
                        <Route path="/checkout/guest/order/:order_id" element={<OrderConfirmation />} />
                        <Route element={<RequireAuthCheckOut />}>
                          <Route path="/checkout/details" element={<ShippingInfo />} />
                          <Route path="/checkout/payment" element={<Payment />} />
                          </Route>
                          <Route path="/checkout/confirmation" element={<OrderConfirmation />} />
                      </Route>
                      {/* public routes */}
                      <Route path="/" element={<Page />} />
                      <Route path="/:page_name/" element={<Page />}>
                        <Route path="/:page_name/:category_name" element={<Category />} >
                          <Route path="/:page_name/:category_name/item/:ref" element={<Item />} />
                          <Route path="/:page_name/:category_name/:subcategory_name" element={<SubCategory />} >
                            <Route path="/:page_name/:category_name/:subcategory_name/item/:ref" element={<Item />} />
                            <Route path="/:page_name/:category_name/:subcategory_name/:subcategory2_name" element={<SubCategory2 />} >
                              <Route path="/:page_name/:category_name/:subcategory_name/:subcategory2_name/item/:ref" element={<Item />} />
                            </Route>
                          </Route>
                        </Route>
                      </Route>
                    </Route>
                  </Route>

                  {/* utility routes */}
                </Route>
              </Route>
            </Route>
            <Route path="/success" element={<Success />} />
          </Route>
        </Route>
      </Route>

      {/* catch all */}
      <Route path="*" element={<Missing />} />
    </Routes >







  );
}

export default App;