import React from 'react'
import { useNavigate, Link } from 'react-router-dom';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Card } from 'react-bootstrap';
import useAuth from '../../hooks/useAuth';

const FeedItem = ({ data }) => {
    
    const navigate = useNavigate();
    const { auth } = useAuth();
    const layout = useStoreState((state) => state.layout);
    const quantity = useStoreState((state) => state.quantity);
    const price = data?.unit_amount / 100
    const add_to_cart = useStoreActions((actions) => actions.add_to_cart);
    const add_to_cart_guest = useStoreActions((actions) => actions.add_to_cart_guest);

    const handleAddToCart = () => {
        if (auth.id) {
            const payload = {
                id: auth?.id,
                new_item: {
                    data: data?.id,
                    quantity: quantity
                }
            }
            add_to_cart(payload)
        }
        else {
            const payload = {
                id: 'guest',
                new_item: {
                    id: data?.id,
                    quantity: quantity
                }
            }
            add_to_cart_guest(payload)
        }
        navigate('/checkout/cart')
    }


    return (
        <div className="d-flex flex-row px-0 py-2" >
            {!data?.subcategory && !data?.subcategory2 &&
                <Link to={`/${data?.page?.name}/${data?.category?.name}/item/${data?.id}`} style={{ textDecoration: "none", backgroundColor: "#E6E6E6", border: 'hidden', borderRadius: '20px' }}>
                    <div className="col-sm-10 pb-4 px-1" style={{ color: 'black' }}>
                        <Card className='mt-3' style={{ width: "12rem", height: "16rem" }}>
                            <img src={`https://${process.env.REACT_APP_IMAGE_DISTRO}/img/${data?.picture}`} className="card-img-top" style={{ height: "16rem" }} alt="..."></img></Card>
                        <h5 className="card-title">{data?.title}</h5>
                        <p className="card-text m-1" >{price.toFixed(2)}</p>
                        <button className='p-0 m-0' style={{ width: '6rem', fontSize: '9px', color: 'white', backgroundColor: `${layout.color_main}`, height: "100%", border: ` solid ${layout?.color_main}`, borderRadius: '50px' }} size='sm' onClick={() => handleAddToCart()}>Add to Cart</button>
                    </div>
                </Link >
            }
            {data?.subcategory && !data?.subcategory2 &&
                <Link to={`/${data?.page?.name}/${data?.category?.name}/${data?.subcategory?.name}/item/${data?.id}`} style={{ textDecoration: "none", backgroundColor: "#E6E6E6", border: 'hidden', borderRadius: '20px' }}>
                    <div className="col-sm-10 pb-4 px-1" style={{ color: 'black' }}>
                        <Card className='mt-3' style={{ width: "12rem", height: "16rem" }}>
                            <img src={`https://${process.env.REACT_APP_IMAGE_DISTRO}/img/${data?.picture}`} className="card-img-top" style={{ height: "16rem" }} alt="..."></img></Card>
                        <h5 className="card-title">{data?.title}</h5>
                        <p className="card-text m-1" >{price.toFixed(2)}</p>
                        <button className='p-0 m-0' style={{ width: '6rem', fontSize: '9px', color: 'white', backgroundColor: `${layout.color_main}`, height: "100%", border: ` solid ${layout?.color_main}`, borderRadius: '50px' }} size='sm' onClick={() => handleAddToCart()}>Add to Cart</button>
                    </div>
                </Link >
            }
            {data?.subcategory && data.subcategory2 &&
                <Link to={`/${data?.page?.name}/${data?.category?.name}/${data?.subcategory?.name}/${data?.subcategory2?.name}/item/${data?.id}`} style={{ textDecoration: "none", backgroundColor: "#E6E6E6", border: 'hidden', borderRadius: '20px' }}>
                    <div className="col-sm-10 pb-4 px-1" style={{ color: 'black' }}>
                        <Card className='mt-3' style={{ width: "12rem", height: "16rem" }}>
                            <img src={`https://${process.env.REACT_APP_IMAGE_DISTRO}/img/${data?.picture}`} className="card-img-top" style={{ height: "16rem" }} alt="..."></img></Card>
                        <h5 className="card-title">{data?.title}</h5>
                        <p className="card-text m-1" >{price.toFixed(2)}</p>
                        <button className='p-0 m-0' style={{ width: '6rem', fontSize: '9px', color: 'white', backgroundColor: `${layout.color_main}`, height: "100%", border: ` solid ${layout?.color_main}`, borderRadius: '50px' }} size='sm' onClick={() => handleAddToCart()}>Add to Cart</button>
                    </div>
                </Link >
            }
        </div >
    )
}

export default FeedItem