
import { useEffect } from "react";
import CartItem from './CartItem'
import CartItemSmall from './CartItemSmall'
import { useNavigate } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useStoreState, useStoreActions } from 'easy-peasy';
import Summary from "./Summary";
import SummarySmall from "./SummarySmall";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import api from '../../api/axios';
import useLocalStorage from "../../hooks/useLocalStorage";

const stripePromise = loadStripe('pk_test_51MMvMaKzUCwJZcNBaRe1YRRgadCztXmrltaGf0Dpalr2jJyYQixZhQNxuFQAmwmnTPkzX9rg3PcfMzEASFpzazjQ00EK7wCSe2');

const Cart = () => {

  const navigate = useNavigate();
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const cart_items = useStoreState((state) => state.cart_items);
  const width = useStoreState((state) => state.width);
  const set_cart_check = useStoreActions((actions) => actions.set_cart_check);
  const set_cart_banner_check = useStoreActions((actions) => actions.set_cart_banner_check);

  const options = {
    appearance: {/*...*/ },
  }
  useEffect(() => {
    set_cart_check(true)
    set_cart_banner_check(true)
    return () => {
      set_cart_check(false)
      set_cart_banner_check(false)
    };
  }, [])

  const handleProceed = async () => {
    const payload = {
      uuid: `${process.env.REACT_APP_UUID}`,
      cart_items: cart_items
    }
    const createPaymentIntentGuest = async (payload) => {
      try {
        const response = await api.post('/tier1/payment/guest/create_intent', payload);
        localStorage.setItem('payment_intent', JSON.stringify(response.data?.payment_info?.body?.id))
      } catch (err) {
        console.log(`Error: ${err.message}`);
      }
      navigate('/checkout/details')
    }
    const createPaymentIntentUser = async (payload) => {
      try {
        const response = await axiosPrivate.post('/tier1/payment/user/create_intent', payload);
        localStorage.setItem('payment_intent', JSON.stringify(response.data?.payment_info?.body?.id))
      } catch (err) {
        console.log(`Error: ${err.message}`);
      }
      navigate('/checkout/details')
    }
    console.log(auth.id)
    if (cart_items.length === 0) {
      alert('Cart is empty')
    }
    else {
      if (!auth.id) {
        createPaymentIntentGuest(payload)
      }
      else {
        createPaymentIntentUser(payload)
      }
    }
  }

  return (
    <>

      {width >= 992 &&
        <Elements stripe={stripePromise} options={options}>
          <div className="col-7">
            <div className="d-flex flex-row justify-content-center" style={{ width: '100%' }}>
              <h3 style={{ color: "black" }}>Cart</h3>
            </div>
            <div className="d-flex flex-row-reverse justify-content-center" style={{ height: "60%", width: "100%" }}>
              <div className="d-flex-column align-items-center justify-content-center" style={{ width: "90%" }}>
                {cart_items?.map(data => (
                  <div key={data.id + 567} style={{ maxWidth: '700px', width: '100%', height: '180px' }}>
                    {!data.id &&
                      <div className="spinner-border text-dark" key={data.id + 123} role="status">
                        <span className="visually-hidden" key={data.id + 345}>Loading...</span>
                      </div>
                    }
                    {data.id &&
                      <CartItem key={data.id} data={data} />
                    }
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="col-5">
            <Summary handleProceed={handleProceed} />
          </div>
        </Elements>
      }
      {width < 992 &&
        <Elements stripe={stripePromise} options={options}>
          <div className="d-flex-column align-items-center" style={{ width: "100%" }}>
            <div className="d-flex flex-row justify-content-center" style={{ height: "30px", width: "100%" }}>
              <h3 style={{ color: "black" }}>Cart</h3>
            </div>
            <div className="d-flex flex-row-reverse" style={{ height: "60%", width: "100%" }}>
              <div className="d-flex-column align-items-center" style={{ width: "100%" }}>
                {cart_items?.map(data => (
                  <div key={data.id + 567} style={{ width: '100%', height: '180px' }}>
                    {!data.id &&
                      <div className="spinner-border text-dark" key={data.id + 123} role="status">
                        <span className="visually-hidden" key={data.id + 345}>Loading...</span>
                      </div>
                    }
                    {data.id &&
                      <CartItemSmall key={data.id} data={data} />
                    }
                  </div>
                ))}
              </div>
            </div>
          </div>
          <SummarySmall handleProceed={handleProceed} />
        </Elements>
      }
    </>
  )
}

export default Cart