import MenuItemsSmall from './MenuItemsSmall';
import { ListGroup, Nav, SplitButton } from 'react-bootstrap';
import { useStoreState, useStoreActions } from 'easy-peasy';
const Dropdown = ({ submenus, dropdown, depthLevel }) => {

  const layout = useStoreState((state) => state.layout);
  depthLevel = depthLevel + 1;
  const dropdownClass = depthLevel > 1 ? 'dropdown-submenu' : '';
  return (
    <>
      {/* <ListGroup.Item className='ps-3 pe-3' style={{ color: 'white', backgroundColor: `${layout?.color_main}` }}>
        <div className="d-flex flex-row justify-content-between align-items-center pt-3" style={{ height: "100%" }} >
          <Nav.Link className='ps-4 py-1' style={{ color: "white", fontWeight: 'bold', fontSize: '20px' }} href="/settings">test</Nav.Link>
          <i onClick={() => handleDropDown2()} style={{ color: 'white' }} class="fa-sharp fa-solid fa-caret-down"></i>
        </div>
      </ListGroup.Item> */}
       {dropdown == true && <>
          {submenus.map((submenu, index) => (
            <MenuItemsSmall
              items={submenu}
              key={index}
              depthLevel={depthLevel}
            />
          ))}
        </>}
    </>
  );
};

export default Dropdown;